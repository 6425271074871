import React from "react";
import {useForm} from "react-hook-form";
import useApiForm from "./Hooks/useApiForm";
import useSave from "./Hooks/useSave";
import useFetch from "./Hooks/useFetch";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Form from "./Components/Form";

function Component({entityName, id, onSave, onBack}) {
    const { watch, register, handleSubmit, setValue, errors, control, reset } = useForm();
    register('id');

    const { status, data, error, isFetching } = useApiForm(entityName);
    const { save, isSaving } = useSave(entityName, () => {if (onSave) {onSave();}});
    const { data: entity } = useFetch(entityName, id);

    const commonFields = {register, errors, control, setValue, watch};

    React.useEffect(() => {
        if (entity && data) {
            reset(entity);
        }
    }, [entity, data]);

    if (!data) {
        return "Loading...";
    }

    return <form onSubmit={handleSubmit(save)}>
        <Form commonFields={commonFields} data={data} />
        <Grid item container style={{marginTop: 20}} >
            <Button size={"small"} onClick={handleSubmit(save)} color={"primary"} variant={"contained"} disableElevation style={{marginRight: 20}}>
                { isSaving ? "Chargement..." : "Enregistrer" }
            </Button>
            {onBack && <Button size={"small"} onClick={onBack} color={"primary"} variant={"outlined"} disableElevation style={{marginRight: 20}}>
                Retour
            </Button>}
        </Grid>
    </form>
}

export default Component
