import { call, put, takeLatest, cancelled } from 'redux-saga/effects'
import {CreateThread, FetchThread, FetchThreads, SendMessage} from '../Endpoint/Message'
import { handleError } from "../../Utils/SagaUtils";

function* fetchThreads(action) {
    try {
        let result = yield call(FetchThreads, action.search, action.page, action.itemsPerPage);
        yield put({type: "FETCH_MESSAGE_THREADS_SUCCEEDED", threads: result.data["hydra:member"], pagination: result.data["hydra:view"]})

    } catch (e) {
        yield put(handleError("FETCH_MESSAGE_THREADS_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* fetchThread(action) {
    try {
        let result = yield call(FetchThread, action.id, action.page, action.itemsPerPage);
        yield put({type: "FETCH_MESSAGE_THREAD_SUCCEEDED", messages: result.data["hydra:member"], pagination: result.data["hydra:view"]})

    } catch (e) {
        yield put(handleError("FETCH_MESSAGE_THREAD_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* sendMessage(action) {
    try {
        let result = yield call(SendMessage, "/api/message_threads/" + action.id, action.message);
        yield put({type: "SEND_MESSAGE_SUCCEEDED", message: result.data})

        // result = yield call(FetchThread, action.id, action.page, action.itemsPerPage);
        // yield put({type: "FETCH_MESSAGE_THREAD_SUCCEEDED", messages: result.data["hydra:member"], pagination: result.data["hydra:view"]})
    } catch (e) {
        yield put(handleError("SEND_MESSAGE_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* createThread(action) {
    try {
        let result = yield call(CreateThread, action.product ? "/api/products/" + action.product.id : null, action.shop ? "/api/shops/" + action.shop.id : null, action.message, action.recipient);
        yield put({type: "CREATE_THREAD_SUCCEEDED", thread: result.data})
    } catch (e) {
        yield put(handleError("CREATE_THREAD_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}


function* mySaga() {
    yield takeLatest("FETCH_MESSAGE_THREADS_REQUESTED", fetchThreads);
    yield takeLatest("FETCH_MESSAGE_THREAD_REQUESTED", fetchThread);
    yield takeLatest("SEND_MESSAGE_REQUESTED", sendMessage);
    yield takeLatest("CREATE_THREAD_REQUESTED", createThread);
}

export default mySaga;