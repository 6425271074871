import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import {getApiUrl, setDefaultHeaders} from "../../Utils/QueryUtils";
import {API_URL} from "../../Config/API";


const useSave = (entityName, onSuccess) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (data) => {
            // TODO : Hack for pictures => find better way
            if (data.medias) {
                for (let i = 0; i < data.medias.length; i++) {
                    if (data.medias[i].picture && data.medias[i].picture.file) {
                        let pictureId = -1;
                        if (data.medias[i].picture.id === -1) {
                            let formData = new FormData();
                            formData.append('file', data.medias[i].picture.file);
                            let result = await axios.post(API_URL + 'api/media_objects', formData, {
                                headers: setDefaultHeaders(
                                    {"Content-type": "multipart/form-data"}
                                )
                            });
                            pictureId = result.data['id'];
                        } else {
                            pictureId = data.medias[i].picture.id;
                        }
                        data.medias[i].picture = '/api/media_objects/' + pictureId;
                    } else {
                        delete data.medias[i].picture;
                    }
                }
            }
            if (data.id) {
                return axios.put(
                    getApiUrl(entityName + 's/' + data.id),
                    data,
                    {headers: setDefaultHeaders()}
                );
            } else {
                return axios.post(
                    getApiUrl(entityName + 's'),
                    data,
                    {headers: setDefaultHeaders()}
                );
            }
        },
        onSuccess: async () => {
            await queryClient.removeQueries(entityName);
            await queryClient.removeQueries(entityName + 's');
            if (onSuccess) {
                onSuccess();
            }
        }
    });

    return { save: mutate, isSaving: isLoading };
}

export default useSave;
