import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./../GlobalStyle";
import * as Constants from "./../Constants"
import CommonStyle from "./CommonStyle";


export default makeStyles((theme) => {
    let CommonStyles = CommonStyle(theme);
    return {
        ...CommonStyles,
        buttonWhite: {
            marginBottom: Constants.GET_SIZE(20),
            width: "100%"
        },
        buttonWhiteLabel: {
            ...GlobalStyle.buttonLabel,
            color: Constants.PINK,
        },

        contentWrapper: {
            ...GlobalStyle.row,
            marginBottom: Constants.GET_SIZE(50),
            width: "100%"
        },
        // subcontentWrapper: {
        //     flex: 1,
        //     marginRight: 130,
        //     marginLeft: Constants.GET_SIZE(50),
        //     [theme.breakpoints.down('xs')]: {
        //         margin: 0
        //     }
        // },
        headerWrapper: {
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: Constants.GET_SIZE(55),
        },
        headerStep: {

            ...GlobalStyle.uppercaseText,
            color: Constants.FONT_GRAY,
            fontWeight: "bold"
        },
        headerStepActive: {
            color: Constants.PINK
        },

        step1Wrapper: {
            flex: 1
        },
        step1Label: {

            marginTop: Constants.GET_SIZE(15),
        },
        step1SubLabel: {
            fontStyle: "italic",

            fontSize: Constants.FONT_SIZE_SMALL,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_SMALL_MOBILE,
            }

        },
        step1CheckboxRow: {
            ...GlobalStyle.row
        },
        checkboxLabel: {

            color: Constants.FONT_GRAY
        },
        checkBox: {
            backgroundColor: Constants.WHITE,
            borderColor: Constants.WHITE,
            width: Constants.GET_SIZE(300)
        },

        column: {
            paddingRight: 20,
            [theme.breakpoints.down('xs')]: {
                paddingRight: 0
            }
        },
        snInputWrapper: {
            ...GlobalStyle.rowCentered,
        },
        imageWrapper: {
            width: Constants.GET_SIZE(150),
            marginLeft: Constants.GET_SIZE(30),
            marginBottom: Constants.GET_SIZE(50),
            alignItems: "center"

        },
        image: {
            width: Constants.GET_SIZE(150),
            height: Constants.GET_SIZE(150),
            marginBottom: Constants.GET_SIZE(20),
            borderRadius: "50%"
        },
        imageButtonLabel: {

            color: Constants.PINK,
            textDecorationLine: "underline"
        },
        imageButton: {},

        picker: {
            width: "100%",
            // marginBottom: 20,
        },
        pickerError: {
            borderColor: Constants.COLOR_ERROR,
            color: Constants.COLOR_ERROR,
        },
    }
});
