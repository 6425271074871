import {API_URL, IMAGES_URL} from "../Config/API";
// import * as ImagePicker from "expo-image-picker";
import loadImage from 'blueimp-load-image';





export function getImageUrl(pictureName, mode) {
    return IMAGES_URL.replace("{mode}", mode) + pictureName;
}

export function getPdfUrl(pdfName) {
    let apiurl = API_URL;
    apiurl = apiurl.replace('/index.php', '')
    apiurl = apiurl + 'media'
    return apiurl + pdfName;
}

export async function pickImage(onSuccess, onError) {
    // try {
    //     let result = await ImagePicker.launchImageLibraryAsync({
    //         mediaTypes: ImagePicker.MediaTypeOptions.All,
    //         allowsEditing: true,
    //         aspect: [4, 3],
    //         quality: 1,
    //     });
    //     if (!result.cancelled) {
    //         const arr = result.uri.split(','), mime = arr[0].match(/:(.*?);/)[1];
    //         console.log(mime);
    //         if (["image/jpeg", "image/png"].indexOf(mime) !== -1) {
    //             onSuccess(result);
    //         } else {
    //             onError({"type": "invalid_mime_type", "message": "Type de fichier invalide."})
    //         }
    //     }
    //
    //     console.log(result);
    // } catch (E) {
    //     onError(onError({"type": "unknown_error", "message": "Une erreur inconnue s'est produite."}));
    //     console.log(E);
    // }
};


export function dataURLtoFile (dataurl, filename) {

    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type:mime});
};

export async function resizeCrop (imageElem, crop, callback) {
    loadImage(
        imageElem.src,
        image => {
            const canvas = document.createElement('canvas');
            // To calculate scale use the image element display
            // in the cropper
            const scaleX = image.naturalWidth / imageElem.width;
            const scaleY = image.naturalHeight / imageElem.height;
            const cropWidth = crop.width && crop.width !== 0 ? crop.width * scaleX : image.naturalWidth;
            const cropHeight = crop.height && crop.height !== 0 ? crop.height * scaleY : image.naturalHeight;

            const pixelRatio = window.devicePixelRatio;
            canvas.width = cropWidth * pixelRatio;
            canvas.height = cropHeight * pixelRatio;

            const ctx = canvas.getContext('2d');
            ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
            ctx.imageSmoothingQuality = 'high';

            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                cropWidth,
                cropHeight,
                0,
                0,
                cropWidth,
                cropHeight
            );

            callback(canvas.toDataURL('image/jpeg'));
        }, {
            orientation: true
        }
    );
};
