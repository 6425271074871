import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./../GlobalStyle";
import * as Constants from "./../Constants"


export default makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        margin: "auto",
        [theme.breakpoints.down('xs')]: {
            width: '90%'
        }
    },
    row: {
        ...GlobalStyle.row,
    },
    title: {
        ...GlobalStyle.uppercaseText,
        fontWeight: "bold",
        width: "max-content",
        marginBottom: Constants.GET_SIZE(15),

        fontSize: Constants.FONT_SIZE_BIG,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    },
    button: {
        justifyContent: "center",
        backgroundColor: Constants.PINK,
        paddingLeft: Constants.GET_SIZE(35),
        paddingRight: Constants.GET_SIZE(35),
        paddingTop: Constants.GET_SIZE(15),
        paddingBottom: Constants.GET_SIZE(15),
    },
    buttonLabel: {
        ...GlobalStyle.buttonLabel
    },

    buttonReturnProduct: {
        justifyContent: "center",
        backgroundColor: Constants.WHITE,
        borderColor: Constants.PINK,
        borderWidth: 1,
        borderStyle: "solid",
        paddingLeft: Constants.GET_SIZE(35),
        paddingRight: Constants.GET_SIZE(35),
        paddingTop: Constants.GET_SIZE(15),
        paddingBottom: Constants.GET_SIZE(15),
        marginTop: Constants.GET_SIZE(10),
        marginBottom: Constants.GET_SIZE(40),
        marginLeft: Constants.GET_SIZE(10),
        marginRight: Constants.GET_SIZE(10),
    },
    buttonReturnProductLabel: {
        ...GlobalStyle.buttonLabel,
        color: Constants.PINK
    },

    contentWrapper: {
        ...GlobalStyle.row,
        maxWidth: 1440,
        width: "100%",
        marginBottom: Constants.GET_SIZE(50),
    },
    contentHeader: {
        ...GlobalStyle.row,
        // justifyContent: "space-between",
        marginBottom: Constants.GET_SIZE(20),
        // marginRight: Constants.GET_SIZE(170),
        [theme.breakpoints.down('xs')]: {
            display: "none"
        }
    },
    contentHeaderMobile: {
        [theme.breakpoints.up('sm')]: {
            display: "none"
        }
    },
    contentHeaderLabel: {
        ...GlobalStyle.uppercaseText,
        marginRight: Constants.GET_SIZE(50),
        textAlign: "left"
    },
    purchasesWrapper: {
        // maxWidth: Constants.GET_SIZE(1000),
        // width: "100%",
        boxSizing: "border-box",
        backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
        marginLeft: 25,
        paddingLeft: 25,
        paddingTop: Constants.GET_SIZE(50),
        paddingBottom: Constants.GET_SIZE(75),
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 5,
            boxSizing: "border-box"
        }
    },
    purchaseWrapper: {
        backgroundColor: Constants.WHITE,
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 2,
        borderStyle: "solid",
        maxWidth: Constants.GET_SIZE(900),
        width: "100%",
        marginBottom: Constants.GET_SIZE(25),
        paddingLeft: Constants.GET_SIZE(25),
        paddingRight: Constants.GET_SIZE(20),
        [theme.breakpoints.down('xs')]: {
            boxSizing: "border-box"
        }
    },
    purchaseHeader: {
        ...GlobalStyle.row,
        justifyContent: "start",
        marginBottom: Constants.GET_SIZE(20),
        marginTop: Constants.GET_SIZE(15),
        width: "100%"
        // marginRight: Constants.GET_SIZE(50)
    },
    purchaseHeaderLabel1: {

        color: Constants.FONT_GRAY,
        width: Constants.GET_SIZE(138),
        textAlign: "left"
    },
    purchaseHeaderLabel2: {

        color: Constants.FONT_GRAY,
        textAlign: "center",
        width: Constants.GET_SIZE(171),
        [theme.breakpoints.down('xs')]: {
            textAlign: "end"
        }
    },
    purchaseHeaderLabel3: {

        color: Constants.FONT_GRAY,
        textAlign: "center",
        width: Constants.GET_SIZE(142)
    },
    purchaseHeaderLabel4: {

        color: Constants.FONT_GRAY,
        textAlign: "center",
        width: Constants.GET_SIZE(156)
    },
    purchaseHeaderLabel5: {

        color: Constants.FONT_GRAY,
        textAlign: "center",
        width: Constants.GET_SIZE(134)
    },
    purchaseHeaderLabel6: {

        color: Constants.PINK,
        textDecorationLine: "underline",
        textAlign: "center",
        width: Constants.GET_SIZE(115),

    },
    purchaseHeaderCaret: {

        color: Constants.PINK
    },
    productWrapper: {
        marginTop: 10,
        marginBottom: 10,
        
    },
    productShopName: {
        fontSize: Constants.FONT_SIZE_MEDIUM,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
        }
    },
    productName: {
        fontSize: Constants.FONT_SIZE_MEDIUM,
        overflowWrap: "break-word",
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
        }
    },

    shopHeaderLabel: {
        fontSize: Constants.FONT_SIZE_MEDIUM,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
        }
    },
    productBrand: {

        color: Constants.FONT_GRAY
    },
    productCategory: {

        color: Constants.FONT_GRAY
    },
    productAddressTitle: {

    },
    productAddress: {

        color: Constants.FONT_GRAY
    },
    productPrice: {

        color: Constants.PINK
    },
    productTextWrapper: {
        marginRight: 25,
        maxWidth: 150,
        width: "100%",
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
        }
    },
    productAddressWrapper: {
        marginRight: Constants.GET_SIZE(25),
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            textAlign: "center",
            marginRight: 0
        }
    },
    productDeliveryInfoWrapper: {
        marginRight: Constants.GET_SIZE(25),
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            textAlign: "center",
            marginRight: 0
        }
    },
    productDeliveryTrackingWrapper: {
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            textAlign: "center",
            justifyContent: "center",
            marginRight: 0
        }
    },
    productImage: {
        width: Constants.GET_SIZE(100),
        height: Constants.GET_SIZE(100),
        marginRight: Constants.GET_SIZE(40),
        [theme.breakpoints.down('xs')]: {
            marginRight: 10,
            marginBottom: 10,
        }
    },
    productDeliveryInfoTitle: {

    },
    productDeliveryTrackingNumber: {

        color: Constants.FONT_GRAY
    },
    productDeliveryTrackingLink: {

        color: Constants.PINK,
        textDecorationLine: "underline",
        textAlign: "center",
    },
    productDeliveryCode: {

        color: Constants.PINK
    },
    productDeliveryCodeLabel: {

        color: Constants.FONT_GRAY
    },
    productButtonWrapper: {
        ...GlobalStyle.row,
        alignItems: "center",
        justifyContent: "center"
    },
    productDeliveryButton: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 10,
        paddingBottom: 10,
        marginTop: Constants.GET_SIZE(5),
        width: "max-content",
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_SMALL_MOBILE,
        }
    },
    productDeliveryButtonLabel: {
        fontSize: Constants.FONT_SIZE_SMALL,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_SMALL_MOBILE,
        }
    },
    productFooter: {
        justifyContent: "center",
        marginTop: Constants.GET_SIZE(25),
        paddingBottom: Constants.GET_SIZE(25),
        [theme.breakpoints.down('xs')]: {
            marginTop: Constants.GET_SIZE(10),
        }
    },
    shopFooter: {
        justifyContent: "center",
        borderBottomColor: Constants.BORDER_GRAY,
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        marginTop: Constants.GET_SIZE(25),
        paddingBottom: Constants.GET_SIZE(25),
        marginBottom: Constants.GET_SIZE(25),
        [theme.breakpoints.down('xs')]: {
            marginTop: Constants.GET_SIZE(10),
        }
    },
    shopFooterLast: {
        borderBottomWidth: 0,
        marginBottom: 0,
    },
    shopFooterButton: {
        marginRight: Constants.GET_SIZE(10),
        marginLeft: Constants.GET_SIZE(10),
    },
    shopFooterButtonFirst: {
        marginRight: Constants.GET_SIZE(10),
    },
    shopFooterButtonDisabled: {
        backgroundColor: Constants.BACKGROUND_GRAY
    },
    shopFooterButtonLast: {
        marginLeft: Constants.GET_SIZE(10),
    },
    shopFooterButtonLabel: {
        fontSize: Constants.GET_FONT_SIZE(12)
    },


    disputeButton: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: 10
        },
        [theme.breakpoints.up('sm')]: {
            marginRight: 20
        }
    },
    picker: {
        width: "100%",
        marginBottom: 10
    }

}));
