import React from 'react';
import clsx from 'clsx';

import Box from "@material-ui/core/Box";

import { TouchableOpacity } from 'react-native-web';
import Text from "../../Component/FontText";

import StylesFunc from "../../../Style/Back/AdminDashboardStyle"
import * as Constants from "../../../Style/Constants";
import { connect } from 'react-redux'
import TextInputIcon from "../../Front/Component/TextInputIcon";
import {useForm} from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { FontAwesome } from '../../../FontAwesome';
import "react-datepicker/dist/react-datepicker.css";
import Image from "react-native-web/dist/exports/Image";
import CalendarIcon from "../../../Assets/Images/calendar_icon.svg";
import {getImageUrl, pickImage, dataURLtoFile} from "../../../Utils/ImageUtils";
import ErrorStyleFunc from "../../Styles/Error";
import CalendarModal from "./CalendarModal"
import Moment from "moment";

function Component ({event, saveEvent, deleteEvent, ref}) {
    let Styles = StylesFunc();
    let ErrorStyle = ErrorStyleFunc();
    const { handleSubmit, setError, setValue, register, errors } = useForm();
    let [city, setCity] = React.useState(event.city);
    let [locationName, setLocationName] = React.useState(event.locationName);
    let [name, setName] = React.useState(event.name);
    let [address, setAddress] = React.useState(event.address);
    let [website, setWebsite] = React.useState(event.website);
    let [picture, setPicture] = React.useState(null);
    let [datetime, setDatetime] = React.useState(event.datetime ? new Date(event.datetime) : new Date());
    let [endDatetime, setEndDatetime] = React.useState(event.endDatetime ? new Date(event.endDatetime) : new Date());
    // let [description, setDescription] = React.useState(event.description);
    let [modalVisible, setModalVisible] = React.useState(false);

    let handleClickSave = () => {
        const realDatetime = Moment(datetime);
        realDatetime.subtract(datetime.getTimezoneOffset(), 'minutes');
        const realEndDatetime = Moment(endDatetime);
        realEndDatetime.subtract(endDatetime.getTimezoneOffset(), 'minutes');
        if (event.picture || picture) {
            saveEvent({
                id: event.id,
                city,
                locationName,
                name,
                address,
                website,
                picture: picture ? dataURLtoFile(picture, "event-" + name.replace(/[^A-Z0-9]/ig, "_") + "-image") : null,
                datetime: realDatetime,
                endDatetime: realEndDatetime
            });
        } else {
            setError("picture", {type: "invalid", message: "Ce champ est obligatoire"})
        }
    };

    let onPickImage = (result) => {
        setPicture(result.uri);
    };


    let onPickImageError = (error) => {
        console.log(error);
    };

    React.useEffect(() => {
        setCity(event.city);
        setLocationName(event.locationName);
        setName(event.name);
        setWebsite(event.website);
        setAddress(event.address);
        setValue("city", event.city);
        setValue("locationName", event.locationName);
        setValue("name", event.name);
        setValue("website", event.website);
        setValue("address", event.address);
        // setValue("description", event.description);
        // setValue("datetime", event.datetime);
    }, [event]);

    return (
        <Box style={{display: "flex", flexDirection: "column", alignItems: "center", width: Constants.GET_SIZE(300), marginBottom: Constants.GET_SIZE(30), paddingRight: 10, paddingLeft: 10, boxSizing: "border-box"}}>
            <div ref={ref} />
            <TextInputIcon
                errors={errors}
                setValue={setCity}
                register={register}
                placeholder={"Ville"}
                rules={{
                    required: "Ce champ est obligatoire"
                }}
                name={"city"}
            />
            <TextInputIcon
                errors={errors}
                setValue={setLocationName}
                register={register}
                placeholder={"Appellation du lieu"}
                rules={{
                    required: "Ce champ est obligatoire"
                }}
                name={"locationName"}
            />

            <Box className={Styles.imageWrapper}>
                {(picture || !(event && event.picture)) && <Image source={picture} className={Styles.image} />}
                {!picture && event && event.picture && <Image source={getImageUrl(event.picture.contentUrl, "medium")} className={Styles.image} />}
                <TouchableOpacity className={Styles.button} onPress={() => pickImage(onPickImage, onPickImageError)}>
                    <Text className={Styles.buttonLabel}>Ajouter une photo</Text>
                </TouchableOpacity>
                <ErrorMessage name={"picture"} errors={errors} as={Text} className={ErrorStyle.error} style={{marginBottom: Constants.GET_SIZE(20), marginTop: Constants.GET_SIZE(10), fontSize: Constants.FONT_SIZE_NORMAL, textAlign: "center"}} />
            </Box>

            <TextInputIcon
                errors={errors}
                setValue={setName}
                register={register}
                placeholder={"Titre de l'évènement"}
                rules={{
                    required: "Ce champ est obligatoire"
                }}
                name={"name"}
            />

            <TouchableOpacity onPress={() => setModalVisible(true)} style={{marginTop: Constants.GET_SIZE(12), marginBottom: Constants.GET_SIZE(13), }}>
                <Image className={Styles.calendarIcon} source={CalendarIcon}/>
            </TouchableOpacity>

            <TextInputIcon
                errors={errors}
                setValue={setAddress}
                register={register}
                placeholder={"Addresse"}
                rules={{
                    required: "Ce champ est obligatoire"
                }}
                name={"address"}
            />

            <TextInputIcon
                errors={errors}
                setValue={setWebsite}
                register={register}
                placeholder={"Site web institutionnel"}
                rules={{
                    required: "Ce champ est obligatoire"
                }}
                name={"website"}
            />


            <Box className={Styles.row} style={{justifyContent: "space-around", width: "100%"}}>
                <TouchableOpacity onPress={() => deleteEvent(event)}>
                    <FontAwesome name={"times-circle"} className={Styles.pictureDeleteIcon} />
                </TouchableOpacity>
                <TouchableOpacity className={Styles.button} onPress={handleSubmit(handleClickSave)}>
                    <Text className={Styles.buttonLabel}>Enregistrer</Text>
                </TouchableOpacity>
            </Box>
            <CalendarModal datetime={datetime} setDatetime={setDatetime} endDatetime={endDatetime} setEndDatetime={setEndDatetime} visible={modalVisible} setVisible={setModalVisible} />
        </Box>
    );
}

const mapStateToProps = state => {
    return {

    }
};

const mapDispatchToProps = dispatch => {
    return {
        blockUser: (id, block, text) => {
            dispatch({type: 'BLOCK_USER_REQUESTED', id, block, text})
        }
    }
};

const VisibleComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default VisibleComponent;
