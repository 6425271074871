import React from 'react';
import { connect } from 'react-redux'
import Moment from "moment";

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Text from "../../Component/FontText";

import {getSingleProductDeliveryPrice} from "../../../Utils/PriceUtils";
import RelaySelectionModal from "./RelaySelectionModal";
import AddressSelectionModal from "./AddressSelectionModal";

import LiveBuyModalStyleFunc from "../../../Style/LiveBuyModalStyle";
import {FetchProduct} from "../../../API/Endpoint/Product";
import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe} from "@stripe/react-stripe-js";

import { PostPurchase } from '../../../API/Endpoint/Purchase'
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomCheckbox from "../../Component/CustomCheckbox";
import * as Constants from "../../../Style/Constants";
import clsx from "clsx";
import Link from "@material-ui/core/Link/Link";
import {InternalLink, LINKS} from "../../Component/InternalLink";
import ErrorStyleFunc from "../../Styles/Error";
import {ErrorMessage} from "@hookform/error-message";
import {useForm} from "react-hook-form";

// In seconds
const MAX_TIME = 120;

function Component ({visible, setVisible, live, liveProduct, proposition, onCancel, onFinished, postPurchase, fetchPointRelais, params, shippingPrices, me, sendReserveProduct, sendReleaseProduct}) {
    let Styles = LiveBuyModalStyleFunc();
    let ErrorStyle = ErrorStyleFunc();

    const { setError, clearErrors, control, errors, watch, register, setValue } = useForm();

    let defaultAddress = null;
    if (me && me.firstname && me.name && me.civility && me.phone && me.shippingAddress && me.shippingZipCode && me.shippingCity) {
        defaultAddress = {
            "firstname": me.firstname,
            "name": me.name,
            "gender": me.civility,
            "phone": me.phone,
            "address": me.shippingAddress,
            "zipcode": me.shippingZipCode,
            "city": me.shippingCity
        }
    }
    let [page, setPage] = React.useState(1);
    let [deliveryMode, setDeliveryMode] = React.useState(-1);
    let [address, setAddress] = React.useState(defaultAddress);
    let [chronopostRelay, setChronopostRelay] = React.useState(null);
    let [mondialRelay, setMondialRelay] = React.useState(null);
    let [addressModalVisible, setAddressModalVisible] = React.useState(false);

    let [cgv, setCGV] = React.useState(false);
    let [selectedCard, setSelectedCard] = React.useState(-1);
    let [memorizeCard, setMemorizeCard] = React.useState(false);

    let [purchase, setPurchase] = React.useState(null);

    let [isLoading, setIsLoading] = React.useState(false);
    let [generalError, setGeneralError] = React.useState(null);
    const [timeLeft, setTimeLeft] = React.useState(null);
    const [currentInterval, setCurrentInterval] = React.useState(null);

    React.useEffect(() => {
        if (visible) {
            setPage(1);
            setDeliveryMode(-1);
            setAddress(defaultAddress);
            setChronopostRelay(null);
            setMondialRelay(null);
            setGeneralError(null);
        } else {
            if (currentInterval) {
                clearInterval(currentInterval)
                setCurrentInterval(null);
            }
        }
    }, [visible]);

    React.useEffect(() => {
        if (liveProduct) {
            sendReserveProduct(liveProduct.product);
            let limit = Moment();
            limit.add(MAX_TIME, "seconds");
            setTimeLeft({
                date: limit,
                sec: MAX_TIME
            });
            if (currentInterval) {
                clearInterval(currentInterval);
                setCurrentInterval(null);
            }
            setCurrentInterval(setInterval(() => {
                setTimeLeft(tl => {
                    if (tl.sec <= 0) {
                        onClickCancel();
                    }
                    return Object.assign({}, tl, {
                        sec: tl.date.diff(Moment(), 'seconds')
                    })
                })
            }, 1000));
        } else {
            setTimeLeft(null);
            if (currentInterval) {
                clearInterval(currentInterval);
                setCurrentInterval(null);
            }
        }
    }, [liveProduct]);


    const stripe = useStripe();
    const elements = useElements();
    const submitForm = async () => {
        setIsLoading(true);
        // We first check if product is still available
        let nbQueries = 1;
        let productSoldOut = false;
        FetchProduct(liveProduct.product.id).then((result) => {
            if (result.data.quantity <= 0) {
                // One product is not available anymore, redirect to cart
                // removeProductFromCart(purchaseProduct.product);
                productSoldOut = true;
            }
            nbQueries--;
        })

        while (nbQueries > 0) {
            await new Promise(r => setTimeout(r, 100));
        }
        if (productSoldOut === true) {
            setIsLoading(false);
            setGeneralError('Désolé, ce produit n\'est plus disponible.');
            return;
        } else {

            // We don't want to let default form submission happen here,
            // which would refresh the page.
            // event.preventDefault();

            if (!stripe || !elements) {
                setIsLoading(false);
                // Stripe.js has not yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return;
            }

            let data = {};

            if (memorizeCard) {
                data["setup_future_usage"] = 'on_session';
            }

            if (selectedCard !== -1) {
                data["payment_method"] = me.savedCardId[selectedCard];
            } else {
                data["payment_method"] = {
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        name: me.username
                    }
                }
            }

            const result = await stripe.confirmCardPayment(purchase.stripeClientSecret, data);

            if (result.error) {
                setIsLoading(false);
                // Show error to your customer (e.g., insufficient funds)
                setError("card", {type: "invalid", message: result.error.message});
            } else {
                // The payment has been processed!
                if (result.paymentIntent.status === 'succeeded') {
                    onFinished(liveProduct.product.id);
                    setPage(3);
                    // Show a success message to your customer
                    // There's a risk of the customer closing the window before callback
                    // execution. Set up a webhook or plugin to listen for the
                    // payment_intent.succeeded event that handles any business critical
                    // post-payment actions.
                }
                setIsLoading(false);
            }
        }
    };
    let prices = [];

    if (liveProduct) {
        prices[0] = getSingleProductDeliveryPrice(liveProduct.product, shippingPrices, params, "chronoPrice", 1);
        prices[1] = getSingleProductDeliveryPrice(liveProduct.product, shippingPrices, params, "chronoRelayPrice", 1);
        prices[2] = getSingleProductDeliveryPrice(liveProduct.product, shippingPrices, params, "mondialRelayPrice", 1);
        prices[3] = 0;
        prices[4] = 0;
    }

    let _getDeliveryChoice = () => {

        if (liveProduct) {
            let clickAndCollect = {valid: liveProduct.product.clickAndCollect && liveProduct.product.shop.events && liveProduct.product.shop.events.length > 0};
            let clickAndCollectEvents = [];
            if (clickAndCollect.valid) {
                for (const event of liveProduct.product.shop.events) {
                    clickAndCollectEvents.push(Moment(event.datetime).locale('fr').format('DD/MM/YYYY') + " à " + event.city);
                }
                clickAndCollect["label"] = "Click & collect - " + clickAndCollectEvents.join(" ou ");
            }

            return (
                <Grid container>
                    <Grid item xs={12}>
                        <Typography className={Styles.deliveryModeWrapperTitle}>Choix du mode de livraison</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Select variant={"outlined"} value={deliveryMode} onChange={(e) => setDeliveryMode(e.target.value)} style={{width: '100%'}}>
                            <MenuItem value={-1}>Choisir le mode de livraison</MenuItem>
                            {liveProduct.product.chronopost && <MenuItem value={0}>Chronopost (livraison à domicile) ({prices[0]}€)</MenuItem>}
                            {liveProduct.product.chronopost && <MenuItem value={1}>Chronopost (point relais) ({prices[1]}€)</MenuItem>}
                            {liveProduct.product.mondialRelay && <MenuItem value={2}>Mondial relay (point relais) ({prices[2]}€)</MenuItem>}
                            {clickAndCollect.valid && <MenuItem value={3}>{clickAndCollect.label} ({prices[3]}€)</MenuItem>}
                            {liveProduct.product.handOver && <MenuItem value={4}>Remise en main propre ({prices[4]}€)</MenuItem>}
                        </Select>
                    </Grid>
                </Grid>

            );
        } else {
            return null;
        }
    };

    let _getAddressChoice = () => {
        if (deliveryMode === 0) {
            return <Grid container direction={"column"} className={Styles.deliveryModeWrapper}>
                <Typography className={Styles.deliveryModeWrapperTitle}>Choix de l'adresse de livraison</Typography>

                <Grid item container>
                    <Box className={Styles.deliveryAddressWrapper}>
                        {address && <Text className={Styles.deliveryName}>{address.firstname} {address.name}</Text>}
                        {address && <Text className={Styles.deliveryName2}>{address.address}</Text>}
                        {address && <Text className={Styles.deliveryName2}>{address.zipcode} {address.city}</Text>}
                    </Box>
                    <Button variant={"contained"} color={"primary"} disableElevation size={"small"} onClick={() => setAddressModalVisible(true)}>Changer l'adresse</Button>
                </Grid>
                {addressModalVisible && <AddressSelectionModal visible={addressModalVisible} setVisible={setAddressModalVisible} setFullAddress={setAddress} defaultAddress={address ? address : {
                    gender: me.civility,
                    address: me.facturationAddress,
                    zipcode: me.facturationZipCode,
                    city: me.facturationCity,
                    name: me.name,
                    firstname: me.firstname,
                    phone: me.phone,
                }} />}
            </Grid>
        } else if (deliveryMode === 1) {
            return <Grid container className={Styles.deliveryModeWrapper}>
                <Grid item xs={12}>
                    <Typography className={Styles.deliveryModeWrapperTitle}>Choix du point relais</Typography>
                </Grid>
                <Grid container>
                    {chronopostRelay && <Box className={Styles.deliveryAddressWrapper}>
                        {/*<Text className={Styles.deliveryName}>DEBUG_NAME</Text>*/}
                        {/*<Text className={Styles.deliveryName2}>DEBUG_STREET</Text>*/}
                        {/*<Text className={Styles.deliveryName2}>55555 DEBUG_CITY</Text>*/}
                        <Text className={Styles.deliveryName}>{chronopostRelay.name}</Text>
                        <Text className={Styles.deliveryName2}>{chronopostRelay.address1}</Text>
                        <Text className={Styles.deliveryName2}>{chronopostRelay.zipCode} {chronopostRelay.city}</Text>
                    </Box>}
                    <Button onClick={() => setAddressModalVisible(true)} variant={"contained"} color={"primary"} disableElevation size={"small"}>
                        Choisir un relay
                    </Button>
                </Grid>
                <RelaySelectionModal visible={addressModalVisible} setVisible={setAddressModalVisible} setValue={setChronopostRelay} type={"Chronopost"} />
            </Grid>
        } else if (deliveryMode === 2) {
            return <Grid container className={Styles.deliveryModeWrapper}>
                <Grid item xs={12}>
                    <Typography className={Styles.deliveryModeWrapperTitle}>Choix du point relais</Typography>
                </Grid>
                <Grid container>
                    <Box className={Styles.deliveryAddressWrapper}>
                        {mondialRelay && <Text className={Styles.deliveryName}>{mondialRelay.name}</Text>}
                        {mondialRelay && <Text className={Styles.deliveryName2}>{mondialRelay.address1}</Text>}
                        {mondialRelay && <Text className={Styles.deliveryName2}>{mondialRelay.zipCode} {mondialRelay.city}</Text>}
                    </Box>
                    <Button onClick={() => setAddressModalVisible(true)} variant={"contained"} color={"primary"} disableElevation size={"small"}>
                        Choisir un relay
                    </Button>
                </Grid>
                <RelaySelectionModal visible={addressModalVisible} setVisible={setAddressModalVisible} setValue={setMondialRelay} type={"MondialRelay"} />
            </Grid>
        } else {
            return null;
        }
    }

    let _getPage = () => {
        if (generalError) {
            return <Grid container className={Styles.dialogContent}>
                <Typography variant={"h4"} className={Styles.dialogTitle}>Achat du produit {liveProduct ? liveProduct.product.name : ''}</Typography>
                <Grid item xs={12}>
                    <Text>Une erreur a eu lieu :</Text>
                </Grid>
                <Grid item xs={12}>
                    <Text>{generalError}</Text>
                </Grid>
            </Grid>
        } else if (page === 1) {
            return <Grid className={Styles.dialogContent} container>
                <Typography variant={"h4"} className={Styles.dialogTitle}>Achat du produit {liveProduct ? liveProduct.product.name : ''}</Typography>
                <Typography style={{marginBottom: 10}}>Vous disposez de 2 minutes pour réaliser le paiement de votre commande. Passé ce délai, la commande sera annulée.</Typography>
                <br />
                {_getDeliveryChoice()}
                {_getAddressChoice()}
            </Grid>
        } else if (page === 2) {
            const createOptions = (fontSize, padding) => {
                return {
                    style: {
                        base: {
                            fontSize,
                            color: 'black',
                            letterSpacing: '0.025em',
                            fontFamily: 'Source Code Pro, monospace',
                            ...(padding ? {padding} : {}),
                        },
                        invalid: {
                            color: '#CA8A8F',
                        },
                    },
                    hidePostalCode: true
                };
            };

            let savedCardsDom = [];
            if (me && me.savedCard) {
                for (let i = 0; i < me.savedCard.length; i++) {
                    savedCardsDom.push(
                        <CustomCheckbox
                            size={Constants.CHECKBOX_SIZE_BIG}
                            containerStyle={{marginBottom: Constants.GET_SIZE(10)}}
                            containerClass={Styles.checkboxContainer}
                            checked={selectedCard === i}
                            title={<Text className={Styles.checkboxLabel}>Carte enregistrée **** {me.savedCard[i]}</Text>}
                            checkedColor={Constants.PINK}
                            uncheckedColor={Constants.FONT_LIGHT_GRAY}
                            checkedIcon={"square"}
                            onPress={() => {setSelectedCard(i)}}
                        />
                    )
                }
            }
            return <Box className={Styles.wrapper}>
                <IconButton onClick={() => setPage(page - 1)} className={Styles.arrowBack}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant={"h4"} className={Styles.dialogTitle}>Achat du produit {liveProduct ? liveProduct.product.name : ''}</Typography>
                <Typography>Prix total de la commande : {parseFloat(prices[deliveryMode]) + parseFloat(proposition ? proposition.price : liveProduct ? liveProduct.product.realPrice : 0)}€</Typography>
                <CustomCheckbox
                    containerClass={Styles.checkboxContainer}
                    checkedColor={Constants.PINK}
                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                    title={<Text className={clsx(Styles.checkboxLabel, {[ErrorStyle.error]: errors["cgv"]})}>J'accepte les <Link target={"_blank"} href={LINKS["STATIC_CGV"]["web"]} style={{width: "max-content", color: Constants.PINK, textDecoration: "underline", textDecorationColor: Constants.PINK}}>
                        conditions générales de ventes.*
                    </Link></Text>}
                    checked={cgv}
                    onPress={() => {clearErrors("cgv"); setCGV(!cgv)}}
                    size={"large"}
                    error={errors['cgv']}
                />
                {/*<ErrorMessage name={"cgv"} errors={errors} as={Text} className={clsx(Styles.error, ErrorStyle.error)} />*/}
                <InternalLink link={"HOMEPAGE"} label={"En savoir plus"} wrapperStyle={Styles.knowMoreText}/>

                {savedCardsDom}
                <CustomCheckbox
                    size={Constants.CHECKBOX_SIZE_BIG}
                    containerClass={Styles.checkboxContainer}
                    checked={selectedCard === -1}
                    title={<Text className={Styles.checkboxLabel}>Ajouter une autre carte</Text>}
                    checkedColor={Constants.PINK}
                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                    checkedIcon={"square"}
                    onPress={() => {setSelectedCard(-1)}}
                />
                {selectedCard === -1 &&
                <Box className={Styles.stripeFieldWrapper}>
                    <CardNumberElement options={createOptions(Constants.FONT_SIZE_MEDIUM)} />
                </Box>}
                {selectedCard === -1 &&<Box className={Styles.stripeFieldWrapper}>
                    <CardExpiryElement options={createOptions(Constants.FONT_SIZE_MEDIUM)} />
                </Box>}
                {selectedCard === -1 &&<Box className={Styles.stripeFieldWrapper}>
                    <CardCvcElement options={createOptions(Constants.FONT_SIZE_MEDIUM)} />
                </Box>}
                <ErrorMessage name={"card"} errors={errors} as={Text} className={ErrorStyle.error} />
                {selectedCard === -1 &&<CustomCheckbox
                    containerClass={Styles.checkboxContainer}
                    checkedColor={Constants.PINK}
                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                    title={<Text className={Styles.checkboxLabel}>Mémoriser cette carte</Text>}
                    checked={memorizeCard}
                    onPress={() => setMemorizeCard(!memorizeCard)}
                    size={Constants.CHECKBOX_SIZE_BIG}
                />}
            </Box>
        } else {
            return <Grid container className={Styles.dialogContent}>
                <Typography variant={"h4"} className={Styles.dialogTitle}>Achat du produit {liveProduct ? liveProduct.product.name : ''}</Typography>
                <Grid item xs={12}>
                    <Text>Félicitations, ta commande a bien été prise en compte !</Text>
                </Grid>
                <Grid item xs={12}>
                    <Text>Un mail te sera transmis avec le récapitulatif de ta commande</Text>
                </Grid>
                <Grid item xs={12}>
                    <Text>A très vite !</Text>
                </Grid>
            </Grid>
        }
    }

    let onClickNext = () => {
        if (page === 1) {
            setIsLoading(true);
            const productsPurchasesData = [{
                "product": "/api/products/" + liveProduct.product.id,
                "quantity": 1,
                "paidPrice": 150,
                "deliveryType": deliveryMode,
                "color": liveProduct.product.colors[0]['@id'],
                "size": liveProduct.product.sizes && liveProduct.product.sizes.length > 0 ? liveProduct.product.sizes[0]["@id"] : null
            }];
            // user, purchaseProducts, deliveryAddress, facturationAddress, mondialRelayId, chronoRelayId, livePurchase
            PostPurchase(me, productsPurchasesData, address, address, mondialRelay ? mondialRelay['id'] : null, chronopostRelay ? chronopostRelay['id'] : null, live['id']).then((response) => {
                setPurchase(response.data);
                setPage(2);
            }).catch((error) => {
                setGeneralError('Une erreur inconnue à eu lieu.');
            }).finally(() => {
                setIsLoading(false);
            });
        } else if (page === 2) {
            if (cgv) {
                submitForm();
            } else {
                setError("cgv", {type: "invalid", message: "Vous devez accepter les conditions générales de vente"});
            }
        }
    }

    let onClickCancel = () => {
        sendReleaseProduct(liveProduct.product);
        setVisible(false);
        onCancel();
    }

    return <Dialog open={visible} fullWidth>
        <Typography color={"primary"} style={{textAlign: "center", fontWeight: "bold"}}>Temps restant : {timeLeft ? timeLeft.sec : 0}s</Typography>
        {_getPage()}

        {(!generalError && page === 2 || (page === 1 && ((deliveryMode === 0 && address) || (deliveryMode === 1 && chronopostRelay) || (deliveryMode === 2 && mondialRelay) || deliveryMode === 3 || deliveryMode === 4))) &&
        <Button variant={"contained"} color={"primary"} disableElevation size={"small"} onClick={onClickNext}>
            Valider
        </Button>}
        <Button variant={"contained"} color={"secondary"} disableElevation size={"small"} onClick={onClickCancel}>{page === 3 || generalError ? 'Revenir au live' : 'Annuler'}</Button>
        {isLoading && <Grid className={Styles.loadingOverlay} container justify={"center"} alignItems={"center"}>
            <CircularProgress color={"primary"}/>
        </Grid>}
    </Dialog>
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        purchase: state.purchase.purchase,
        me: state.user.me,
        shippingPrices: state.family.shippingPrices,
        params: state.params.params
    }
};

const mapDispatchToProps = dispatch => {
    return {
        postPurchase: (user, purchaseProducts, deliveryAddress, facturationAddress, mondialRelayId, chronopostRelayId) => {
            dispatch({type: 'POST_PURCHASE_REQUESTED', user, purchaseProducts, deliveryAddress, facturationAddress, mondialRelayId, chronopostRelayId, livePurchase: true})
        },
        fetchPointRelais: () => {
            dispatch({type: 'FETCH_POINT_RELAIS_REQUESTED'})
        },
    }
};

const VisibleComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default VisibleComponent;
