import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    wrapper: {
        ...GlobalStyle.rowCentered,
        height: Constants.GET_SIZE(40),
    },
    iconWrapper: {
        backgroundColor: Constants.BACKGROUND_GRAY,
        width: Constants.GET_SIZE(40),
        minWidth: 'initial',
        height: Constants.GET_SIZE(40),
        marginRight: Constants.GET_SIZE(7),
        marginLeft: Constants.GET_SIZE(7),
        justifyContent: "center",
        alignItems: "center",
    },
    smsIcon: {
        width: Constants.GET_SIZE(18),
        height: Constants.GET_SIZE(14),
    },
    messengerIcon: {
        width: Constants.GET_SIZE(22),
        height: Constants.GET_SIZE(22),
        objectFit: "contain !important"
    },
    whatsappIcon: {
        width: Constants.GET_SIZE(18),
        height: Constants.GET_SIZE(16),
    },
    mailIcon: {
        width: Constants.GET_SIZE(18),
        height: Constants.GET_SIZE(12),
        objectFit: "contain !important"
    }
}));
