import React from "react";

import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import Text from "../Component/FontText"
import LeftMenu from "./Partial/LeftMenu"
import Base from "./Base"
import StylesFunc from "../../Style/MiddleOffice/MyWalletStyle"
import * as Constants from "../../Style/Constants"
import TextInputIcon from "../Front/Component/TextInputIcon";
import {useForm} from "react-hook-form";
import { LINKS } from "../Component/InternalLink";


function Component({navigation, history, screenKey, token, user, saveShop, savePicture, receivePayment, isLoading}) {
    let Styles = StylesFunc();
    const { setValue, setError, handleSubmit, register, errors, watch } = useForm();

    let [bankName, setBankName] = React.useState(user && user.shop ? user.shop.bank : "");
    let [bankAgency, setBankAgency] = React.useState(user && user.shop ? user.shop.bankAgency : "");
    let [bankAddress, setBankAddress] = React.useState(user && user.shop ? user.shop.bankAddress : "");
    let [bankZipcode, setBankZipcode] = React.useState(user && user.shop ? user.shop.bankZipCode : "");
    let [bankCity, setBankCity] = React.useState(user && user.shop ? user.shop.bankCity : "");
    let [bankAccountOwner, setBankAccountOwner] = React.useState(user && user.shop ? user.shop.bankAccountOwner : "");
    let [iban, setIban] = React.useState(user && user.shop ? user.shop.bankAccountIBAN : "");
    let [bic, setBic] = React.useState(user && user.shop ? user.shop.bankAccountBIC : "");

    let [confirmation, setConfirmation] = React.useState(false);


    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    React.useEffect(() => {
        loadShop();
    }, [user]);

    let loadShop = () => {
        if (user && user.shop) {
            // Bank info
            setBankName(user.shop.bank);
            setBankAgency(user.shop.bankAgency);
            setBankAddress(user.shop.bankAddress);
            setBankZipcode(user.shop.bankZipCode);
            setBankCity(user.shop.bankCity);
            setBankAccountOwner(user.shop.bankAccountOwner);
            setIban(user.shop.bankAccountIBAN);
            setBic(user.shop.bankAccountBIC);
            setValue("bankName", user.shop.bank);
            setValue("bankAgency", user.shop.bankAgency);
            setValue("bankAddress", user.shop.bankAddress);
            setValue("bankZipcode", user.shop.bankZipCode);
            setValue("bankCity", user.shop.bankCity);
            setValue("bankAccountOwner", user.shop.bankAccountOwner);
            setValue("iban", user.shop.bankAccountIBAN);
            setValue("bic", user.shop.bankAccountBIC);
        } else if (user && !user.shop) {
            history.push("/creer_ma_boutique");
        }
    };

    let handleClickContinue = (data) => {
        setConfirmation(false);
        let error = false;
        let amount = data.amount;
        if (parseFloat(amount) != amount) {
            setError("amount", {type: "invalid", message: "Ce champ est invalide"});
            error = true;
        } else if (amount < 1) {
            setError("amount", {type: "invalid", message: "Le montant minimum est de 1€"});
            error = true;
        } else if (amount > user.shop.currentMoney) {
            setError("amount", {type: "invalid", message: "Vous n'avez pas assez de fonds"});
            error = true;
        }

        if (!error) {
            receivePayment(amount);
            setValue("amount", "");
            setTimeout(() => setConfirmation(true), 1000);
        }
    };

    let _getWallet = () => {
        let stripeError = user && user.shop && (!user.shop.stripeAccountStatus || user.shop.stripeAccountStatus.status !== "stripe_account_ok");
        return <Grid container className={Styles.walletWrapper}>
            <Grid item xs={12} className={Styles.totalAmountWrapper}>
                <Text className={Styles.totalAmountLabel}>Somme totale : &nbsp;</Text>
                <Text className={Styles.totalAmountValue}>{user && user.shop ? user.shop.currentMoney : 0} €</Text>
            </Grid>

            <Grid container item xs={12} style={{marginBottom: 20}}>
                <Grid item xs={12} sm={3}>
                    <Text style={{lineHeight: "60px"}}>Effectuer un virement : </Text>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextInputIcon
                        errors={errors}
                        register={register}
                        placeholder={"Montant*"}
                        wrapperClass={Styles.transferRequestInput}
                        rules={{
                            required: "Ce champ est obligatoire",
                        }}
                        name={"amount"}
                    />
                </Grid>
            </Grid>
            {confirmation &&
                <Grid container item xs={12} style={{marginBottom: 20}}>
                    <Text color={"primary"} style={{lineHeight: "60px"}}>Votre virement a bien été effectué.</Text>
                </Grid>}
            <Button variant={"contained"} color={"primary"} disableElevation className={Styles.button} disabled={!isLoading && stripeError} onClick={handleSubmit(handleClickContinue)}>
                Effectuer le virement
            </Button>
            {!isLoading && stripeError && <Text className={Styles.stripeStatusErrorText}>
                Certaines informations sont manquantes sur votre compte. Vous ne pouvez pas effectuer le virement tant que vous n'aurez pas fourni les documents demandés dans la page "Ma boutique".
            </Text>}
        </Grid>
    };

    let _getBankInfo = () => {
        return <Grid container xs={12} justify={"space-between"}>
            <Grid item xs={12} className={Styles.bottomBlockHeader}>
                <Text className={Styles.bottomBlockTitle}>Vos coordonnées bancaires : </Text>
                <Hidden xsDown>
                    <Button onClick={() => history.push(LINKS["MY_BANK_INFO"]["web"])} variant={"contained"} color={"primary"} disableElevation className={Styles.button}>Mettre à jour</Button>
                </Hidden>
            </Grid>
            <Grid item xs={12} sm={5}>
                <TextInputIcon
                    defaultValue={bankName}
                    label={"Nom de la banque*"}
                    disabled={true}
                />
                <TextInputIcon
                    defaultValue={bankAgency}
                    label={"Nom de l'agence"}
                    disabled={true}
                />
                <TextInputIcon
                    defaultValue={bankAddress}
                    label={"Adresse de l'agence"}
                    disabled={true}
                />
                <TextInputIcon
                    defaultValue={bankZipcode}
                    label={"Code postal"}
                    disabled={true}
                />
                <TextInputIcon
                    defaultValue={bankCity}
                    label={"Ville"}
                    disabled={true}
                />
            </Grid>
            <Grid item xs={12} sm={5}>
                <TextInputIcon
                    defaultValue={bankAccountOwner}
                    label={"Titulaire du compte"}
                    disabled={true}
                />
                <TextInputIcon
                    defaultValue={iban}
                    label={"IBAN"}
                    disabled={true}
                />
                <TextInputIcon
                    defaultValue={bic}
                    label={"BIC"}
                    disabled={true}
                />
            </Grid>
            <Hidden smUp>
                <Button onClick={() => history.push(LINKS["MY_BANK_INFO"]["web"])} variant={"contained"} color={"primary"} disableElevation className={Styles.button}>Mettre à jour</Button>
            </Hidden>
        </Grid>
    };

    return <Base content={
        <Box className={Styles.container}>
            <Text className={Styles.title}>Mon porte-monnaie</Text>
            <Grid container>
                <Hidden xsDown>
                    <LeftMenu currentPage={"MY_WALLET"} />
                </Hidden>
                <Box className={Styles.subcontentWrapper}>
                    {_getWallet()}
                    {_getBankInfo()}
                </Box>
            </Grid>
        </Box>
    } />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.me,
        isLoading: state.loading.isLoadingGeneral
    }
};

const mapDispatchToProps = dispatch => {
    return {
        saveShop: (shop, picture) => {
            dispatch({type: 'SAVE_SHOP_REQUESTED', shop, picture})
        },
        savePicture: (pictureData) => {
            dispatch({type: 'SAVE_PICTURE_REQUESTED', pictureData})
        },
        receivePayment: (amount) => {
            dispatch({type: 'RECEIVE_PAYMENT_REQUESTED', amount})
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
