import axios from "axios";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getApiUrl, setDefaultHeaders} from "../../Utils/QueryUtils";


const useFetch = (entityName, id) => {
    const queryClient = useQueryClient();
    let { status, data, error, isFetching } = useQuery({
        queryKey: [entityName, id],
        queryFn: async () => {
            if (id) {
                const {data} = await axios.get(
                    getApiUrl(entityName + 's/' + id),
                    {headers: setDefaultHeaders()}
                );
                return data;
            } else {
                return null;
            }
        }
    });

    return { status, data, error, isFetching };
}

export default useFetch;
