import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    wrapper: {
        ...GlobalStyle.row,
        width: "100%",
        margin: "auto",
        marginTop: Constants.GET_SIZE(88),
        marginBottom: Constants.GET_SIZE(30),
        maxWidth: 1440,
        [theme.breakpoints.down('xs')]: {
            width: "90%",
            margin: "auto"
        },
    },
    row: {
        ...GlobalStyle.row
    },
    leftColumn: {
        maxWidth: 320,
        [theme.breakpoints.down('xs')]: {
            maxWidth: "100%"
        },
    },
    rightColumn: {
        alignContent: "flex-start",
        marginLeft: 15,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0
        }
    },
    productWrapper: {
        height: Constants.GET_SIZE(379),
        margin: "auto",
        marginBottom: 25
    },
    productWrapperWrapper: {
        maxWidth: 350,
        [theme.breakpoints.down('xs')]: {
            maxWidth: "100%"
        },
    },

    // Header
    homepageHeaderWrapper: {
        maxHeight: 200,
        height: 200,
    },
    homepageHeaderLeftWrapper: {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        position: "relative",
        width: "100%",
        borderRight: "1px solid white"
    },

    homepageHeaderText: {
        color: Constants.FONT_LIGHT_GRAY,
        // width: 200
    },

    headerImageTitle: {
        ...GlobalStyle.headerImageTitle,
        position: "absolute",
        bottom: 50,
        left: 37,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM,
            bottom: 20,
            left: 25,
            width: "85%",
            overflowWrap: "break-word"
        }
    },

    homepageHeaderRectangle: {
        position: "absolute",
        bottom: 0,
        left: 37,
        width: 205,
        height: 15,
        [theme.breakpoints.down('xs')]: {
            left: 25,
        }
    },

    homepageHeaderPinkRectangle: {
        backgroundColor: Constants.PINK
    },

    memberSinceText: {

        marginTop: Constants.GET_SIZE(25),
        color: Constants.FONT_LIGHT_GRAY
    },

    shopType: {
        marginTop: Constants.GET_SIZE(25),
        color: Constants.PINK,
        textAlign: "center"
    },
    description: {

        marginTop: Constants.GET_SIZE(25),
    },
    secondBlockText: {

        fontWeight: "bold",
        lineHeight: Constants.FONT_SIZE_MEDIUM
    },
    buttonsRow: {
        alignItems: "center",
        marginTop: Constants.GET_SIZE(25),
    },
    paginator: {
        ...GlobalStyle.rowCenteredHW,
        paddingBottom: Constants.GET_SIZE(30),
        width: "100%",
        marginBottom: 0,
        marginTop: Constants.GET_SIZE(25),
    },
    page: {

        color: Constants.PINK
    },
    currentPage: {
        color: Constants.BLACK
    },
    shopImage: {
        width: Constants.GET_SIZE(188),
        height: Constants.GET_SIZE(217),
        borderRadius: "3%",
        objectFit: "contain"
    },
    grayBlock: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
        alignItems: "center",
        marginBottom: Constants.GET_SIZE(5),
        padding: Constants.GET_SIZE(25),
        paddingTop: Constants.GET_SIZE(50),
        paddingBottom: Constants.GET_SIZE(50),

        width: "100%",
    },
    button: {
        ...GlobalStyle.button,
        width: Constants.GET_SIZE(193),
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: Constants.GET_SIZE(60)
    },
    buttonLabel: {
        ...GlobalStyle.buttonLabel
    },
    subscribeButton: {
        ...GlobalStyle.button,
        backgroundColor: Constants.WHITE,
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        marginBottom: Constants.GET_SIZE(25),
        marginTop: Constants.GET_SIZE(25),
        width: Constants.GET_SIZE(193),
        paddingLeft: 0,
        paddingRight: 0,
    },
    subscribeButtonLabel: {
        ...GlobalStyle.buttonLabel,
        color: Constants.BLACK
    },
    shareTitle: {

        marginBottom: Constants.GET_SIZE(25),
    },
    starWrapper: {
        width: Constants.GET_SIZE(120),
        flexDirection: "row",
        justifyContent: "space-between"
    },
    socialMediaRow: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 10
        }
    },
    socialMedia: {
        width: Constants.GET_SIZE(25),
        height: Constants.GET_SIZE(25),
        // width: "100%",
        // height: "100%",
    },
    otherSocialMedia: {
        ...GlobalStyle.uppercaseText,
        color: Constants.WHITE,
        fontWeight: "bold",
        fontSize: Constants.GET_FONT_SIZE(17),
        // width: "100%",
        // height: "100%",
    },
    socialMediaWrapper: {
        width: 27,
        height: 27,
        marginLeft: Constants.GET_SIZE(5),
        marginRight: Constants.GET_SIZE(5),
        justifyContent: "center",
        alignItems: "center"
    },
    otherSocialMediaWrapper: {
        width: 27,
        height: 27,
        marginLeft: Constants.GET_SIZE(5),
        marginRight: Constants.GET_SIZE(5),
        justifyContent: "center",
        backgroundColor: Constants.PINK,
        borderRadius: "50%",
        alignItems: "center"
    },
    emptyShopLabel: {
        marginTop: Constants.GET_SIZE(25),
        width: "100%",
        textAlign: "center",
        fontSize: Constants.FONT_SIZE_BIG,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    }
}));
