import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {Provider} from 'react-redux';
import {createStackNavigator} from '@react-navigation/stack';
import { ThemeProvider } from '@material-ui/core/styles';

import {loadStripe} from '@stripe/stripe-js';

import { store, persistor } from './Store/index'
import { PersistGate } from 'redux-persist/integration/react'

import DefaultTheme from './Theme/DefaultTheme'
import {
    QueryClient,
    QueryClientProvider,
} from 'react-query';


import './Assets/fonts/Montserrat-Medium.ttf';
import {SnackbarProvider} from "notistack";


const Stack = createStackNavigator();

const queryClient = new QueryClient();

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_test_1p2q8ujiqHhzZ1T2p1HKamlX00C4UGwbly");


ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ThemeProvider theme={DefaultTheme}>
                        <SnackbarProvider maxSnack={3}>
                            <App />
                        </SnackbarProvider>
                    </ThemeProvider>
                </PersistGate>
            </Provider>
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
