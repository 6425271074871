import React from "react";
import { TouchableOpacity } from "react-native-web";

import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Link from "@material-ui/core/Link";

import { connect } from 'react-redux';
import { withRouter, useParams } from "react-router-dom";

import Text from "../Component/FontText"
import Base from "./Base"
import StylesFunc from "../../Style/Back/LitigeEditStyle"
import * as Constants from "../../Style/Constants";
import ConfirmationModal from "../Front/Component/ConfirmationModal";


function Component({history, fetchDispute, dispute,deleteDispute, resolveDispute}) {
    let Styles = StylesFunc();
    let { id }  = useParams();
    let [ deleteModalVisible, setDeleteModalVisible ] = React.useState(false);

    React.useEffect(() => {
        if (id) {
            fetchDispute(id);
        }
    }, [id]);

    let handleClickBack = () => {
        if (history.location.state && history.location.state.listState) {
            history.push("/admin/liste", {listState: history.location.state.listState})
        } else {
            history.push("/admin/liste", {listState: {tab: "DISPUTE", page: 1, search: ""}})
        }
    };

    let confirmDelete = () => {
        setDeleteModalVisible(false);

        let search;
        let page;
        if (history.location.state && history.location.state.listState) {
            search = history.location.state.listState.search;
            page = history.location.state.listState.page;
        } else {
            search = "";
            page = 1;
        }
        deleteDispute(dispute.id, search, 10, page);
        handleClickBack();
    };

    let handleClickDelete = () => {
        setDeleteModalVisible(true);
    };

    let validateDispute = (winner) => {
        if (winner === "SELLER") {
            resolveDispute(dispute.id, 1);
        } else if (winner === "BUYER") {
            resolveDispute(dispute.id, 2);
        }
    }

    let _getPage = () => {
        let author;
        if (dispute.author.id === dispute.shop.owner.id) {
            author = "Vendeur";
        } else if (dispute.author.id === dispute.purchase.user.id) {
            author = "Acheteur";
        }
        return <Box>
            <Box className={Styles.row} style={{marginBottom: Constants.GET_SIZE(10), marginTop: Constants.GET_SIZE(25)}}>
                <Text className={Styles.label}>Vendeur :</Text>
                <Text className={Styles.value} style={{marginRight: Constants.GET_SIZE(15)}}>{dispute.shop.owner.firstname + " " + dispute.shop.owner.name}</Text>
                <Text className={Styles.value}>{dispute.shop.owner.email}</Text>
            </Box>
            <Box className={Styles.row} style={{marginBottom: Constants.GET_SIZE(10), marginTop: Constants.GET_SIZE(25)}}>
                <Text className={Styles.label}>Acheteur :</Text>
                <Text className={Styles.value} style={{marginRight: Constants.GET_SIZE(15)}}>{dispute.purchase.user.firstname + " " + dispute.purchase.user.name}</Text>
                <Text className={Styles.value}>{dispute.purchase.user.email}</Text>
            </Box>
            <Box className={Styles.row} style={{marginBottom: Constants.GET_SIZE(10), marginTop: Constants.GET_SIZE(25)}}>
                <Text className={Styles.label}>Litige demandé par :</Text>
                <Text className={Styles.value} style={{marginRight: Constants.GET_SIZE(15)}}>{author}</Text>
            </Box>
            <Box className={Styles.row} style={{marginBottom: Constants.GET_SIZE(10), marginTop: Constants.GET_SIZE(25)}}>
                <Text className={Styles.label}>Statut du litige :</Text>
                <Text className={Styles.value} style={{marginRight: Constants.GET_SIZE(15)}}>{dispute.status === 0 ? "En cours" : dispute.status === 1 ? "Résolu (en faveur du vendeur)" : "Résolu (en faveur de l'acheteur)"}</Text>
            </Box>
            <Box className={Styles.row} style={{marginBottom: Constants.GET_SIZE(10), marginTop: Constants.GET_SIZE(25)}}>
                <Text className={Styles.label}>Déclaration :</Text>
                <Text className={Styles.value} style={{marginRight: Constants.GET_SIZE(15)}}>{dispute.message}</Text>
            </Box>
            <Box className={Styles.row} style={{marginBottom: Constants.GET_SIZE(10), marginTop: Constants.GET_SIZE(25)}}>
                <Text className={Styles.label}>Produit concerné:</Text>
                <Link href={"/admin/editer_produit/" + dispute.product.id}>
                    <Text className={Styles.label}>{dispute.product.name}</Text>
                </Link>
            </Box>

            {dispute.status === 0 && <Box className={Styles.row} style={{marginBottom: Constants.GET_SIZE(10), marginTop: Constants.GET_SIZE(25)}}>
                <TouchableOpacity className={Styles.button} style={{marginRight: Constants.GET_SIZE(15)}} onPress={() => validateDispute("BUYER")}>
                    <Text className={Styles.buttonLabel}>Rembourser le client</Text>
                </TouchableOpacity>
                <TouchableOpacity className={Styles.button} onPress={() => validateDispute("SELLER")}>
                    <Text className={Styles.buttonLabel}>Débloquer les fonds du vendeur</Text>
                </TouchableOpacity>
            </Box>}
        </Box>
    };

    return <Base
        title={"Résolution du litige"}
        currentScreen={"ADMIN_LIST"}
        content={
            <Box style={{width: "100%"}}>
                {dispute && _getPage()}
                <Box className={Styles.row} style={{paddingRight: Constants.GET_SIZE(50)}}>
                    {dispute && !dispute.deleted && <TouchableOpacity className={Styles.barButton} style={{marginRight: Constants.GET_SIZE(15)}} onPress={() => handleClickDelete()}>
                        <Text className={Styles.barButtonLabel}>{"Archiver"}</Text>
                    </TouchableOpacity>}
                    <TouchableOpacity className={Styles.buttonWhite} onPress={() => handleClickBack()}>
                        <Text className={Styles.buttonWhiteLabel}>{"Retour"}</Text>
                    </TouchableOpacity>
                </Box>
                <ConfirmationModal
                    visible={deleteModalVisible}
                    setVisible={setDeleteModalVisible}
                    title={"Suppression du litige"}
                    message={"Un litige clos ne pourra pas être ré-ouvert. Êtes-vous sûr de vouloir archiver ce litige ?"}
                    onConfirm={() => confirmDelete()}
                    onCancel={() => {setDeleteModalVisible(false)}}/>
            </Box>
    } />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.currentUser,
        dispute: state.dispute.currentDispute
    }
};

const mapDispatchToProps = dispatch => {
    return {
        payBuyer: (purchase) => {
            // dispatch({type: 'SAVE_USER_REQUESTED', user})
        },
        paySeller: (purchase) => {
            // dispatch({type: 'FETCH_USER_REQUESTED', id})
        },
        fetchDispute: (id) => {
            dispatch({type: 'FETCH_DISPUTE_REQUESTED', id})
        },
        deleteDispute: (id, search, itemsPerPage, page) => {
            dispatch({type: 'DELETE_DISPUTE_REQUESTED', id, search, itemsPerPage, page})
        },
        resolveDispute: (id, status) => {
            dispatch({type: 'UPDATE_DISPUTE_REQUESTED', id, status})
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
