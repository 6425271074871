import React from 'react';
import { useDispatch } from 'react-redux'
import axios from "axios";
import {getApiUrl, setDefaultHeaders} from "../../Utils/QueryUtils";

export function useLiveList(tab, date) {
    const [liveList, setLiveList] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (isLoading) {
            dispatch({type: 'START_LOADING_REQUESTED'});
        } else {
            dispatch({type: 'START_LOADING_SUCCEEDED'})
        }
    }, [isLoading]);

    React.useEffect(() => {
        if (tab && (tab === 'en_cours' || date)) {
            setIsLoading(true);
            let params = {};
            if (tab === 'en_cours') {
                params.date = 'now';
            } else {
                params.date = date.format();
            }
            axios.get(
                getApiUrl('lives', params),
                {headers: setDefaultHeaders()}
            ).then((response) => {
                let fetchedLives = response.data['hydra:member'];
                let sortedLives = [];
                for (let i = 0; i < 24; i++) {
                    sortedLives.push([]);
                }
                fetchedLives.map(fetchedLive => {
                    let slot = new Date(fetchedLive.startAt).getHours();
                    sortedLives[slot].push(fetchedLive);
                    return 0;
                });
                setLiveList(sortedLives);
                setIsLoading(false);
            }).catch((error) => {
                console.error('Hooks/Live.useLiveList: ', error);
                setIsLoading(false);
            });
        } else {
            setLiveList([]);
        }
    }, [tab, date]);

    return [liveList, isLoading];
}


export function useLiveProducts(id) {
    const [products, setProducts] = React.useState([]);
    const [notBuyable, setNotBuyable] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        if (id) {
            setIsLoading(true);
            axios.get(
                getApiUrl('lives/' + id + "/live_products"),
                {headers: setDefaultHeaders()}
            ).then((response) => {
                let ps = response.data['hydra:member'];
                setProducts(ps);
                setIsLoading(false);
            }).catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
        }
    }, [id]);

    React.useEffect(() => {
        setProducts(ps => {
            if (products.length > 0) {
                let result = [...ps];
                for (const p of ps) {
                    if (notBuyable.includes(p.product.id)) {
                        p.buyable = false;
                    } else {
                        p.buyable = true;
                    }
                }
                return result;
            } else {
                return ps;
            }
        })
    }, [notBuyable]);

    const decrementProductQuantity = productId => {
        setProducts(oldProducts => {
            const index = oldProducts.findIndex(p => p.product.id === productId);
            if (index !== -1) {
                return [
                    ...oldProducts.slice(0, index),
                    Object.assign({}, oldProducts[index], {
                        product: Object.assign({}, oldProducts[index].product, {
                            quantity: oldProducts[index].product.quantity - 1
                        })
                    }),
                    ...oldProducts.slice(index + 1),
                ]
            } else {
                return oldProducts;
            }
        })
    }

    const reserveProduct = productId => {
        setProducts(oldProducts => {
            const index = oldProducts.findIndex(p => p.product.id === productId);
            if (index !== -1) {
                return [
                    ...oldProducts.slice(0, index),
                    Object.assign({}, oldProducts[index], {
                        product: Object.assign({}, oldProducts[index].product, {
                            reserved: oldProducts[index].product.reserved ? (oldProducts[index].product.reserved + 1) : 1
                        })
                    }),
                    ...oldProducts.slice(index + 1),
                ]
            } else {
                return oldProducts;
            }
        })
    }

    const releaseProduct = productId => {
        setProducts(oldProducts => {
            const index = oldProducts.findIndex(p => p.product.id === productId);
            if (index !== -1) {
                return [
                    ...oldProducts.slice(0, index),
                    Object.assign({}, oldProducts[index], {
                        product: Object.assign({}, oldProducts[index].product, {
                            reserved: oldProducts[index].product.reserved ? (oldProducts[index].product.reserved - 1) : 0
                        })
                    }),
                    ...oldProducts.slice(index + 1),
                ]
            } else {
                return oldProducts;
            }
        })
    }

    const refreshReservations = (reservedProducts) => {
        setProducts(oldProducts => {
            let newProducts = [];
            for (let i = 0; i < oldProducts.length; i++) {
                let product = oldProducts[i];
                if (reservedProducts[product.product.id]) {
                    newProducts.push(Object.assign({} , product, {
                        product: Object.assign({}, product.product, {
                            reserved: reservedProducts[product.product.id]
                        })
                    }));
                } else {
                    newProducts.push(Object.assign({} , product, {
                        product: Object.assign({}, product.product, {
                            reserved: 0
                        })
                    }));
                }
            }
            return newProducts;
        });
    }

    return [products, isLoading, setNotBuyable, reserveProduct, releaseProduct, refreshReservations, decrementProductQuantity];
}

export function useLive(id) {
    const [live, setLive] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        if (id) {
            setIsLoading(true);
            axios.get(
                getApiUrl('lives/' + id),
                {headers: setDefaultHeaders()}
            ).then((response) => {
                setLive(Object.assign({}, response.data, {isFirstLoad: true}));
                setIsLoading(false);
            }).catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
        }
    }, [id]);

    const updateLiveState = (newState) => {
        setLive(l => l && l.id ? Object.assign({}, l, {isFirstLoad: false, streamStatus: newState}) : l)
    }

    const updateLiveNbConnected = (add) => {
        setLive(l => l && l.id ? Object.assign({}, l, {isFirstLoad: false, nbConnected: l.nbConnected + (add ? 1 : -1)}) : l)
    }

    const updateLiveAdminState = (newState) => {
        setLive(l => l && l.id ? Object.assign({}, l, {isFirstLoad: false, adminConnectedToChat: newState}) : l)
    }

    const updateLiveVideoState = (newState) => {
        setLive(l => l && l.id ? Object.assign({}, l, {isFirstLoad: false, videoStatus: newState}) : l)
    }

    const subscribeToLive = () => {
        const path = live.amISubscribed ? "unsubscribe" : "subscribe";
        setLive(oldLive => Object.assign({}, oldLive, {amISubscribed: !live.amISubscribed, isFirstLoad: false}));
        axios.get(
            getApiUrl('lives/' + id + '/' + path),
            {headers: setDefaultHeaders()}
        ).then((response) => {
            setLive(oldLive => Object.assign({}, oldLive, response.data, {isFirstLoad: false}));
        }).catch((error) => {
            console.log(error);
        });
    }

    return [live, updateLiveState, updateLiveAdminState, updateLiveVideoState, subscribeToLive, updateLiveNbConnected, isLoading];
}

