import React from "react";
import clsx from 'clsx';

import StylesFunc from "../../../Style/LiveSquareStyle"

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Rating from "@material-ui/lab/Rating";
import DotIcon from '@material-ui/icons/FiberManualRecord';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Text from "../../Component/FontText";

import {useLiveProducts} from '../../../API/Hook/Live';
import {getImageUrl} from "../../../Utils/ImageUtils";
import Link from "@material-ui/core/Link";
import UserIcon from "../../../Assets/Images/user_icon.svg";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function LiveSquare({live}) {
    let Styles = StylesFunc();

    let [liveProducts, isLoading] = useLiveProducts(live.id);
    let [currentPicture, setCurrentPicture] = React.useState(0);

    const handleSetCurrentPicture = (index) => {
        if (!isNaN(index)) {
            setCurrentPicture(index);
        }
    }
    let _getPicture = () => {
        return <AutoPlaySwipeableViews
            enableMouseEvents
            onChangeIndex={handleSetCurrentPicture}
            index={currentPicture}
            className={Styles.picturesWrapper}>
            {liveProducts.sort((a, b) => a.position - b.position).map((liveProduct, key) =>
                <CardMedia image={getImageUrl(liveProduct.product.pictures[0].contentUrl, "medium")} className={Styles.image} key={'picture-' + live.id + '-' + liveProduct.product.id} />
            )}
        </AutoPlaySwipeableViews>;
    };

    let _getPictureOverlay = () => {
        if (liveProducts.length > 1) {
            return <Grid
                className={Styles.picturesOverlay}
                container
                justify={"center"}>
                {liveProducts.map(
                    (liveProduct, key) => <DotIcon
                        onClick={() => setCurrentPicture(key)}
                        className={clsx(Styles.dotIcon, {[Styles.dotIconSelected]: currentPicture === key})}
                        key={'picture-dot-' + live.id + '-' + liveProduct.product.id} />
                )}
            </Grid>;
        } else {
            return null;
        }
    };

    return (
        <Grid item xs={12} sm className={Styles.wrapper}>
            <Link href={"/voir_live/" + live.id}>
                <Card elevation={0}>
                    <CardContent
                        className={Styles.cardActionArea}>
                        <Box className={Styles.loaderWrapper}>
                            <Box className={Styles.loaderSubWrapper}>
                                {isLoading && <CircularProgress color={"primary"} className={Styles.loader}/> }
                            </Box>
                        </Box>
                        { _getPicture()}
                        {_getPictureOverlay()}
                        {live.streaming && <Text className={Styles.liveNow}>Live</Text>}
                    </CardContent>
                    <CardActionArea
                        className={Styles.cardActionArea}>
                        <Grid container xs={12} className={Styles.infoLine}>
                            <Grid item xs={2}>
                                {live.owner.picture ?
                                    <CardMedia image={getImageUrl(live.owner.picture.contentUrl, "medium")} className={Styles.shopPicture} />
                                    : <CardMedia component={"img"} src={UserIcon} className={Styles.shopPicture} />
                                }
                            </Grid>
                            <Grid item xs={10}>
                                <Text className={Styles.shopName}>{live.owner.name}</Text>
                                <Rating name="read-only" value={live.owner.note} readOnly size={"small"} style={{marginLeft: -3}}/>
                                {liveProducts.length > 0 && <Text className={Styles.nbProducts}>{liveProducts.length} article{liveProducts.length > 1 ? 's' : ''}</Text>}
                            </Grid>
                        </Grid>
                    </CardActionArea>
                </Card>
            </Link>
        </Grid>
    );
}

export default LiveSquare;
