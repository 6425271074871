import React from "react";
import clsx from 'clsx';
import {Picker} from "react-native";
import {ImageBackground, TouchableOpacity} from "react-native-web";

import Box from "@material-ui/core/Box";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";

import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Moment from "moment";
// import { FontAwesome } from '../../FontAwesome';
import { FontAwesome } from '../../FontAwesome';
import UserIcon from "../../Assets/Images/user_icon.svg";

// import Picker from "../Component/CustomPicker";
import Text from "../Component/FontText"
import ViewPictureModal from "./Component/ViewPictureModal"
import Base from "./Base"
import StylesFunc from "../../Style/Back/AdminDashboardStyle"
import * as Constants from "../../Style/Constants";
import "react-datepicker/dist/react-datepicker.css";
import TextInputIcon from "../Front/Component/TextInputIcon";
import HorizontalSlider from "../Front/Component/HorizontalSlider";
import Image from "react-native-web/dist/exports/Image";
import {getImageUrl, pickImage, dataURLtoFile} from "../../Utils/ImageUtils";
import EventForm from "./Component/EventForm"
import {useForm} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";
import ErrorStyleFunc from "../Styles/Error";
import { LINKS } from "../Component/InternalLink"
import UploadPictureIcon from "../../Assets/Images/upload_picture_icon.svg"
import ViewPictureIcon from "../../Assets/Images/view_picture_icon.svg"
import ConfirmationModal from "../Front/Component/ConfirmationModal";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Grid from "@material-ui/core/Grid";


function Component({history, navigation, token, params, tendancesSauvages, lovedShops, storeEvents, searchShopsList, searchProductsList, getParams, getTendancesSauvages, getLovedShops, getEvents, deleteTendanceSauvage, deleteLovedShop, deleteEvent, saveTendanceSauvage, saveLovedShop, saveEvent, saveBanners, saveTendanceSauvagePicture, searchShops, searchProducts, saveParam}) {
    let ErrorStyle = ErrorStyleFunc();
    const { clearErrors, setError, control, errors } = useForm();
    let Styles = StylesFunc();
    let [newTendanceSauvageName, setNewTendanceSauvageName] = React.useState("");
    let [newTendanceSauvageId, setNewTendanceSauvageId] = React.useState("");
    let [displayTendanceSauvageAutocomplete, setDisplayTendanceSauvageAutocomplete] = React.useState(false);
    let [newLovedShopName, setNewLovedShopName] = React.useState("");
    let [newLovedShopId, setNewLovedShopId] = React.useState("");
    let [displayShopAutocomplete, setDisplayShopAutocomplete] = React.useState(false);

    let [modalBannerVisible, setModalBannerVisible] = React.useState(false);
    let [modalEventVisible, setModalEventVisible] = React.useState(false);

    let [commission, setCommission] = React.useState("");
    let [autoPayDelay, setAutoPayDelay] = React.useState("");
    let [liveMaxProducts, setLiveMaxProducts] = React.useState("");
    let [livePrice, setLivePrice] = React.useState("");

    let bannersState = {};
    for (let i = 1; i < 4; i++) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        let [link, setLink] = React.useState("CUSTOM_LINK");
        // eslint-disable-next-line react-hooks/rules-of-hooks
        let [customLink, setCustomLink] = React.useState("");
        // eslint-disable-next-line react-hooks/rules-of-hooks
        let [description, setDescription] = React.useState("");
        // eslint-disable-next-line react-hooks/rules-of-hooks
        let [picture, setPicture] = React.useState("");
        // eslint-disable-next-line react-hooks/rules-of-hooks
        let [showPictureModal, setShowPictureModal] = React.useState(false);
        bannersState[i] = {
            link: link,
            setLink: setLink,
            customLink: customLink,
            setCustomLink: setCustomLink,
            description: description,
            setDescription: setDescription,
            picture: picture,
            setPicture: setPicture,
            showPictureModal: showPictureModal,
            setShowPictureModal: setShowPictureModal
        }
    }

    let [tendanceSauvagePicture, setTendanceSauvagePicture] = React.useState(null);

    let [events, setEvents] = React.useState([]);

    React.useEffect(() => {
        getParams();
        getTendancesSauvages();
        getLovedShops();
        getEvents();
    }, []);

    React.useEffect(() => {
        if (params) {
            params.map(param => {
                // Load banners
                if (param.name === 'banners') {
                    let value = JSON.parse(param.value);
                    for (const index in value) {
                        let banner = value[index];
                        bannersState[banner.id].setDescription(banner.description);
                        if (banner.redirect in LINKS) {
                            bannersState[banner.id].setLink(banner.redirect);
                        } else {
                            bannersState[banner.id].setLink("CUSTOM_LINK");
                            bannersState[banner.id].setCustomLink(banner.redirect);
                        }
                        if (param.pictures) {
                            bannersState[banner.id].setPicture(param.pictures[index]);
                        }
                    }
                }
                // Load tendance sauvage picture
                else if (param.name === 'tendance_sauvage_picture' && param.pictures) {
                    setTendanceSauvagePicture(param.pictures[0]);
                }
                // Load commission
                else if (param.name === 'commission') {
                    setCommission(param.value);
                }
                // Load auto payment delay
                else if (param.name === 'auto_payment_delay') {
                    setAutoPayDelay(param.value);
                }
                // Load live max products
                else if (param.name === 'live_max_products') {
                    setLiveMaxProducts(param.value);
                }
                // Load live price
                else if (param.name === 'live_price') {
                    setLivePrice(param.value);
                }
            })
        }


    }, [params]);

    React.useEffect(() => {
        setEvents(storeEvents);
    }, [storeEvents]);


    let handleSaveBanners = () => {
        saveBanners("banners", [
            {
                "id":1,
                "redirect": bannersState[1].link === 'CUSTOM_LINK' ? bannersState[1].customLink : bannersState[1].link,
                "description":bannersState[1].description,
                "picture": bannersState[1].picture
            },
            {
                "id":2,
                "redirect": bannersState[2].link === 'CUSTOM_LINK' ? bannersState[2].customLink : bannersState[2].link,
                "description":bannersState[2].description,
                "picture": bannersState[2].picture
            },
            {
                "id":3,
                "redirect": bannersState[3].link === 'CUSTOM_LINK' ? bannersState[3].customLink : bannersState[3].link,
                "description": bannersState[3].description,
                "picture": bannersState[3].picture
            }
        ])

        setModalBannerVisible(true);
    };

    let handleClickUploadPicture = (id) => {
        pickImage(result => {
            if (id === "TENDANCE_SAUVAGE") {
                setTendanceSauvagePicture({
                    "id": -1,
                    "dataURI": result.uri,
                    "file": dataURLtoFile(result.uri, "tendance-sauvage-" + id + "-image")
                })
                saveTendanceSauvagePicture({
                    "id": -1,
                    "dataURI": result.uri,
                    "file": dataURLtoFile(result.uri, "tendance-sauvage-" + id + "-image")
                });
            } else {
                bannersState[id].setPicture({
                    "id": -1,
                    "dataURI": result.uri,
                    "file": dataURLtoFile(result.uri, "banner-" + id + "-image")
                });
            }
        }, error => {
            console.log(error);
        });
    };

    let handleClickViewPicture = (id) => {
        bannersState[id].setShowPictureModal(true);
    };

    let _getBannersBlock = () => {
        let bannerLines = params.map(param => {
            let result = [];
            if (param.name === 'banners') {
                let value = JSON.parse(param.value);
                for (const banner of value) {
                    result.push(<Box className={Styles.row}>
                        {banner.id === 1 && <Text className={clsx(Styles.label, Styles.bannerLabel)}>Bannière 1 (HOMEPAGE)</Text>}
                        {banner.id === 2 && <Text style={{visibility: "hidden"}} className={clsx(Styles.label, Styles.bannerLabel)}>Bannière 1 (HOMEPAGE)</Text>}
                        {banner.id === 3 && <Text className={clsx(Styles.label, Styles.bannerLabel)}>Bannière 2 (BLOGUEURS)</Text>}
                        <TouchableOpacity onPress={() => handleClickUploadPicture(banner.id)}>
                            <Image source={UploadPictureIcon} style={{height: Constants.GET_SIZE(31), width: Constants.GET_SIZE(31), marginRight: Constants.GET_SIZE(15)}}/>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => handleClickViewPicture(banner.id)}>
                            <Image source={ViewPictureIcon} style={{height: Constants.GET_SIZE(33), width: Constants.GET_SIZE(40), marginRight: Constants.GET_SIZE(15)}}/>
                        </TouchableOpacity>
                        <ViewPictureModal picture={bannersState[banner.id].picture} visible={bannersState[banner.id].showPictureModal} setVisible={bannersState[banner.id].setShowPictureModal}/>
                        <Select
                            variant={"outlined"}
                            className={clsx(Styles.pickerWrapper)}
                            onChange={(event) => {
                                bannersState[banner.id].setLink(event.target.value);
                            }}
                            value={bannersState[banner.id].link}
                        >
                            {Object.keys(LINKS).map(key =>
                                LINKS[key].label ? <MenuItem value={key} >{LINKS[key].label}</MenuItem> : null
                            )}
                            <MenuItem value={'CUSTOM_LINK'} >Adresse manuelle</MenuItem>
                        </Select>
                        <TextInputIcon
                            value={bannersState[banner.id].customLink}
                            setValue={bannersState[banner.id].setCustomLink}
                            placeholder={"Adresse manuelle"}
                            wrapperClass={Styles.inputWrapper}
                        />
                        <TextInputIcon
                            value={bannersState[banner.id].description}
                            setValue={bannersState[banner.id].setDescription}
                            placeholder={"Description"}
                            wrapperClass={Styles.inputWrapper}
                            />
                    </Box>);
                }
            }
            return result;
        })

        return <Box>
            {bannerLines}
            <TouchableOpacity style={{width: "max-content", alignSelf: "center", marginBottom: Constants.GET_SIZE(25)}} className={Styles.button} onPress={() => handleSaveBanners()}>
                <Text className={Styles.buttonLabel}>Enregistrer</Text>
            </TouchableOpacity>
        </Box>
    };

    let _getTendanceSauvagePicture = () => {
        return <Box>
            <Text className={Styles.label}>TENDANCE SAUVAGE</Text>
            <Text style={{color: Constants.FONT_GRAY}} className={Styles.label}>PHOTO TENDANCE SAUVAGE</Text>

            <Button onClick={() => handleClickUploadPicture("TENDANCE_SAUVAGE")}>
                <CardMedia
                    className={Styles.lovedShopCard}
                    src={tendanceSauvagePicture && tendanceSauvagePicture.id === -1 ? tendanceSauvagePicture.dataURI : tendanceSauvagePicture ? getImageUrl(tendanceSauvagePicture.contentUrl, "medium"): null}
                    component={"img"}
                    imageStyle={{borderRadius: "5%"}} />
            </Button>
        </Box>
    }

    let handleAddTendanceSauvage = () => {
        clearErrors("tendanceSauvage");
        if (newTendanceSauvageId) {
            if (tendancesSauvages.length < 20) {
                saveTendanceSauvage(newTendanceSauvageId);
            } else {
                setError("tendanceSauvage", {type: "invalid", message: "Limite de 20 tendances sauvages atteinte"});
            }
        } else {
            setError("tendanceSauvage", {type: "invalid", message: "Vous devez renseigner un produit"});
        }

    };

    let handleRemoveTendanceSauvage = (product) => {
        deleteTendanceSauvage(product.id);
    };

    let handleTendanceSauvageAutocomplete = (field, value) => {
        if (field === "name") {
            setNewTendanceSauvageName(value)
            if (value.length >= 3) {
                searchProducts(value);
                setDisplayTendanceSauvageAutocomplete(true);
            }
        } else if (field === "id") {
            setNewTendanceSauvageId(value)
        }
    };

    let handleClickTendanceSauvageAutocomplete = (product) => {
        setDisplayTendanceSauvageAutocomplete(false);
        setNewTendanceSauvageId(product.id);
        setNewTendanceSauvageName(product.name);
    };

    let handleTendanceSauvageSearchBlur = () => {
        setDisplayTendanceSauvageAutocomplete(false);
    };

    let _getTendancesSauvagesBlock = () => {
        return <Box className={Styles.grayBlock}>
            <Box style={{zIndex: 999}} className={Styles.row}>
                <Text className={Styles.label} style={{fontSize: Constants.GET_SIZE(12), width: "max-content"}}>TENDANCES SAUVAGES</Text>
                <Box className={Styles.searchShopWrapper}>
                    <TextInputIcon
                        value={newTendanceSauvageName}
                        setValue={(value) => handleTendanceSauvageAutocomplete("name", value)}
                        placeholder={"Nom du produit"}
                        wrapperClass={Styles.inputWrapper}
                        onBlur={handleTendanceSauvageSearchBlur}
                    />
                    <Box style={{visibility: displayTendanceSauvageAutocomplete && searchProductsList.length > 0 ? 'visible' : 'hidden'}} className={Styles.shopAutocompleteWrapper}>
                        {searchProductsList.map(product => <TouchableOpacity className={Styles.shopAutocompleteButton} onPress={() => handleClickTendanceSauvageAutocomplete(product)}>
                            <Text className={Styles.shopAutocompleteLabel}>{product.name}</Text>
                        </TouchableOpacity>)}
                    </Box>
                </Box>
                <TextInputIcon
                    value={newTendanceSauvageId}
                    setValue={(value) => handleTendanceSauvageAutocomplete("id", value)}
                    placeholder={"Numéro de produit"}
                    wrapperClass={Styles.inputWrapper}
                />
                <TouchableOpacity className={Styles.button} onPress={() => handleAddTendanceSauvage()}>
                    <Text className={Styles.buttonLabel}>Enregistrer</Text>
                </TouchableOpacity>
            </Box>
            <ErrorMessage name={"tendanceSauvage"} errors={errors} as={Text} className={ErrorStyle.error} style={{marginBottom: Constants.GET_SIZE(20), marginTop: Constants.GET_SIZE(10), fontSize: Constants.FONT_SIZE_NORMAL, textAlign: "center"}} />
            {tendancesSauvages.slice().reverse().map(product => <Box className={Styles.row}>
                <Text style={{width: Constants.GET_SIZE(250)}} className={Styles.rowTendanceSauvageText}>{product.name}</Text>
                <Text style={{width: Constants.GET_SIZE(150)}} className={Styles.rowTendanceSauvageText}>{product.shop.name}</Text>
                <Text style={{width: Constants.GET_SIZE(300)}} className={Styles.rowTendanceSauvageText}>Produit {product.category.name} {product.brand ? product.brand.value : ""}</Text>
                <Image className={Styles.rowTendanceSauvageText} style={{width: Constants.GET_SIZE(35), height: Constants.GET_SIZE(35), marginRight: Constants.GET_SIZE(20)}} source={getImageUrl(product.pictures[0] ? product.pictures[0].contentUrl : null, "small")} />
                {/*<FontAwesome name={"image"} className={Styles.iconPicture} />*/}
                <TouchableOpacity onPress={() => handleRemoveTendanceSauvage(product)}>
                    <FontAwesome name={"times-circle"} className={Styles.pictureDeleteIcon} />
                </TouchableOpacity>
            </Box>)}
        </Box>
    };

    let handleAddLovedShop = () => {
        if (newLovedShopId) {
            if (lovedShops.length < 16) {
                saveLovedShop(newLovedShopId);
            } else {
                setError("lovedShop", {type: "invalid", message: "Limite de 16 boutiques coup de coeur atteinte"});
            }
        } else {
            setError("lovedShop", {type: "invalid", message: "Vous devez renseigner une boutique"});
        }
    };

    let handleRemoveLovedShop = (shop) => {
        deleteLovedShop(shop);
    };

    let handleLovedShopAutocomplete = (field, value) => {
        if (field === "name") {
            setNewLovedShopName(value)
            if (value.length >= 3) {
                searchShops(value);
                setDisplayShopAutocomplete(true);
            }
        } else if (field === "id") {
            setNewLovedShopId(value)
        }
    };

    let handleClickLovedShopAutocomplete = (shop) => {
        setDisplayShopAutocomplete(false);
        setNewLovedShopId(shop.id);
        setNewLovedShopName(shop.name);
    };

    let handleShopSearchBlur = () => {
        setDisplayShopAutocomplete(false);
    };

    let _getLovedShopsBlock = () => {
        return <Box className={Styles.whiteBlock}>
            <Box className={Styles.row} style={{zIndex: 999}}>
                <Text className={Styles.label} style={{fontSize: Constants.GET_SIZE(12), width: "max-content"}}>NOS BOUTIQUES COUP DE COEUR</Text>
                <Box className={Styles.searchShopWrapper}>
                    <TextInputIcon
                        value={newLovedShopName}
                        setValue={(value) => handleLovedShopAutocomplete("name", value)}
                        placeholder={"Nom boutique"}
                        wrapperClass={Styles.inputWrapper}
                        onBlur={handleShopSearchBlur}
                    />
                    <Box className={Styles.shopAutocompleteWrapper} style={{visibility: displayShopAutocomplete && searchShopsList.length > 0 ? 'visible' : 'hidden'}}>
                        {searchShopsList.map(shop => <TouchableOpacity class={Styles.shopAutocompleteButton} onPress={() => handleClickLovedShopAutocomplete(shop)}>
                            <Text class={Styles.shopAutocompleteLabel}>{shop.name}</Text>
                        </TouchableOpacity>)}
                    </Box>
                </Box>
                <TextInputIcon
                    value={newLovedShopId}
                    setValue={(value) => handleLovedShopAutocomplete("id", value)}
                    placeholder={"Numéro boutique"}
                    wrapperClass={Styles.inputWrapper}
                />
                <TouchableOpacity className={Styles.button} onPress={() => handleAddLovedShop()}>
                    <Text className={Styles.buttonLabel}>Enregistrer</Text>
                </TouchableOpacity>
            </Box>
            <ErrorMessage name={"lovedShop"} errors={errors} as={Text} className={ErrorStyle.error} style={{marginBottom: Constants.GET_SIZE(20), marginTop: Constants.GET_SIZE(10), fontSize: Constants.FONT_SIZE_NORMAL, textAlign: "center"}} />
            <HorizontalSlider
                carouselKey={"loved-shops"}
                nbElementsPerPage={3}
                wrapperClass={Styles.slider}
                elementList={lovedShops.map(shop => <TouchableOpacity onPress={() => history.push("/shop/" + shop.id)} style={{width:280}}>
                        <ImageBackground className={Styles.lovedShopCard} source={shop.picture ? getImageUrl(shop.picture.contentUrl, "medium") : UserIcon} imageStyle={{borderRadius: "5%", backgroundSize: 'contain', objectFit: 'contain'}}>
                            <Text className={Styles.lovedShopCardText}>
                                {shop.name}
                            </Text>
                        </ImageBackground>
                        <TouchableOpacity onPress={() => handleRemoveLovedShop(shop)} className={Styles.pictureDeleteIconWrapper}>
                            <FontAwesome name={"times-circle"} className={Styles.pictureDeleteIcon} />
                        </TouchableOpacity>
                    </TouchableOpacity>
                )}
                arrows={true}
                // rowStyle={ProductStyles.shopOtherProductsSlider}
            />
        </Box>
    };

    const scrollToRef = (ref) => ref.current.scrollIntoView({behavior: "smooth"});
    let lastEvent = React.useRef(null);

    let handleAddEvent = () => {
        setEvents([...events, {internalId: events.length}]);
        scrollToRef(lastEvent);
    };

    let handleSaveEvent = (event) => {
        saveEvent(event)
        setModalEventVisible(true);
    };

    let handleRemoveEvent = (event) => {
        if (event.id) {
            deleteEvent(event)
        } else {
            const newEvents = [...events.slice(0, event.internalId), ...events.slice(event.internalId + 1)];
            setEvents(newEvents)
        }
    };

    let _getEventsBlock = () => {
        return <Box>
            <Box className={Styles.whiteBlock}>
                <Box className={Styles.row}>
                    <Text className={Styles.label} style={{fontSize: Constants.GET_SIZE(12), width: "max-content"}}>PROCHAINS VIDES DRESSINGS</Text>
                    <TouchableOpacity onPress={() => handleAddEvent()}>
                        <FontAwesome name={"plus-circle"} className={Styles.addIcon} />
                    </TouchableOpacity>
                </Box>
            </Box>
            <Box className={Styles.grayBlock}>
                <Box className={Styles.row} style={{flexWrap: "wrap"}}>
                    {events && events.map((event, index) => <div>{index === events.length - 1 ? <div ref={lastEvent} /> : null}<EventForm event={event} saveEvent={handleSaveEvent} deleteEvent={handleRemoveEvent} /></div>)}
                </Box>
            </Box>
        </Box>
    };


    let _getParamsBlock = () => {
        return <Box>
            <Box className={Styles.whiteBlock}>
                <Box style={{maxWidth: 700}}>
                    <Grid container justify={"space-between"} className={Styles.row} style={{marginBottom: 10}}>
                        <Text className={Styles.label} style={{flexGrow: 1}}>Commission: </Text>
                        <TextInputIcon
                            setValue={setCommission}
                            value={commission}
                            suffix={"%"}
                            wrapperClass={Styles.smallInputWrapper}
                            inputComponentClass={Styles.smallInput}
                        />
                        <Button variant={"contained"} color={"primary"} disableElevation size={"small"}  className={Styles.button} onClick={() => saveParam("commission", commission)}>
                            <Text className={Styles.buttonLabel}>Enregistrer</Text>
                        </Button>
                    </Grid>
                    <Grid container justify={"space-between"} className={Styles.row} style={{marginBottom: 10}}>
                        <Text className={Styles.label} style={{flexGrow: 1}}>Délais du paiement automatique: </Text>
                        <TextInputIcon
                            setValue={setAutoPayDelay}
                            value={autoPayDelay}
                            suffix={" jours"}
                            wrapperClass={Styles.smallInputWrapper}
                            inputComponentClass={Styles.smallInput}
                        />
                        <Button variant={"contained"} color={"primary"} disableElevation size={"small"}  className={Styles.button} onClick={() => saveParam("auto_payment_delay", autoPayDelay)}>
                            <Text className={Styles.buttonLabel}>Enregistrer</Text>
                        </Button>
                    </Grid>
                    <Grid container justify={"space-between"} className={Styles.row} style={{marginBottom: 10}}>
                        <Text className={Styles.label} style={{flexGrow: 1}}>Nombre de produits max dans un live: </Text>
                        <TextInputIcon
                            setValue={setLiveMaxProducts}
                            value={liveMaxProducts}
                            suffix={" produits"}
                            wrapperClass={Styles.smallInputWrapper}
                            inputComponentClass={Styles.smallInput}
                        />
                        <Button variant={"contained"} color={"primary"} disableElevation size={"small"} className={Styles.button} onClick={() => saveParam("live_max_products", liveMaxProducts)}>
                            <Text className={Styles.buttonLabel}>Enregistrer</Text>
                        </Button>
                    </Grid>
                    <Grid container justify={"space-between"} className={Styles.row} style={{marginBottom: 10}}>
                        <Text className={Styles.label} style={{flexGrow: 1}}>Prix d'un live: </Text>
                        <TextInputIcon
                            setValue={setLivePrice}
                            value={livePrice}
                            suffix={" €"}
                            wrapperClass={Styles.smallInputWrapper}
                            inputComponentClass={Styles.smallInput}
                        />
                        <Button variant={"contained"} color={"primary"} disableElevation size={"small"} className={Styles.button} onClick={() => saveParam("live_price", livePrice)}>
                            <Text className={Styles.buttonLabel}>Enregistrer</Text>
                        </Button>
                    </Grid>
                </Box>
            </Box>
        </Box>
    };

    return <Base
        title={"Administration manuelle"}
        currentScreen={"ADMIN_DASHBOARD"}
        content={
            <Box className={Styles.container}>
                {_getBannersBlock()}
                {_getTendanceSauvagePicture()}
                {_getTendancesSauvagesBlock()}
                {_getLovedShopsBlock()}
                {_getEventsBlock()}
                {_getParamsBlock()}
                <ConfirmationModal
                    visible={modalBannerVisible}
                    setVisible={setModalBannerVisible}
                    message={"Les bannières ont bien été enregistrées"}
                    onConfirm={() => {setModalBannerVisible(false)}} />
                <ConfirmationModal
                    visible={modalEventVisible}
                    setVisible={setModalEventVisible}
                    message={"Le vide-dressing a bien été enregistré"}
                    onConfirm={() => {setModalEventVisible(false)}} />
            </Box>
        }
    />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.me,
        params: state.params.params,
        tendancesSauvages: state.product.tendancesSauvagesList,
        lovedShops: state.shop.lovedShopsList,
        storeEvents: state.videDressing.videDressingsList,
        searchShopsList: state.shop.shopsList,
        searchProductsList: state.product.productsList
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getParams: () => {
            dispatch({type: 'FETCH_PARAMS_REQUESTED'})
        },
        getTendancesSauvages: () => {
            dispatch({type: 'FETCH_TENDANCES_SAUVAGES_REQUESTED'})
        },
        getLovedShops: () => {
            dispatch({type: 'FETCH_LOVED_SHOPS_REQUESTED'})
        },
        getEvents: () => {
            dispatch({type: 'FETCH_VIDE_DRESSINGS_REQUESTED'})
        },
        deleteTendanceSauvage: (id) => {
            dispatch({type: 'SAVE_PRODUCT_REQUESTED', product: {id, tendanceSauvage: false}, pictures: []})
        },
        deleteLovedShop: (shop) => {
            dispatch({type: 'SAVE_SHOP_REQUESTED', shop: {id: shop.id, loved: false, lovedAt: null}})
        },
        deleteEvent: (event) => {
            dispatch({type: 'DELETE_VIDE_DRESSING_REQUESTED', id: event.id})
        },
        saveTendanceSauvage: (id) => {
            dispatch({type: 'SAVE_PRODUCT_REQUESTED', product: {id, tendanceSauvage: true}, pictures: []})
        },
        saveLovedShop: (shopId) => {
            let now = new Date();
            dispatch({type: 'SAVE_SHOP_REQUESTED', shop: {id: shopId, loved: true, lovedAt: now.toISOString()}})
        },
        saveEvent: (event) => {
            dispatch({type: 'SAVE_VIDE_DRESSING_REQUESTED', videDressing: event})
        },
        saveBanners: (paramName, paramValue) => {
            dispatch({type: 'SAVE_BANNERS_REQUESTED', paramName, paramValue})
        },
        saveTendanceSauvagePicture: (picture) => {
            dispatch({type: 'SAVE_TENDANCE_SAUVAGE_PICTURE_REQUESTED', picture})
        },
        searchShops: (search) => {
            dispatch({type: 'SEARCH_SHOPS_REQUESTED', name: search, itemsPerPage: 5, page: 1, hideLoader: true})
        },
        searchProducts: (search) => {
            dispatch({type: 'SEARCH_PRODUCTS_REQUESTED', name: search, itemsPerPage: 5, page: 1, hideLoader: true})
        },
        saveParam: (paramName, paramValue) => {
            dispatch({type: 'SAVE_PARAM_REQUESTED', paramName, paramValue})
        },
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
