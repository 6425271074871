const initialState = {
    threadsList: [],
    threadsListPagination: {
        first:1,
        last:1
    },
    messages: [],
    messagesPagination: {
        first: 1,
        last: 1
    },
    newlyCreatedThread: null
};


function reduc(state = initialState, action) {
    let first;
    let last;
    let index;
    let result = {};
    switch (action.type) {

        case "CLEAN_MESSAGES_LIST":
            return Object.assign({}, state, {
                messages: [],
                messagesPagination: {
                    first: 1,
                    last: 1
                },
            });

        case "FETCH_MESSAGE_THREADS_SUCCEEDED":
            first = new URLSearchParams(action.pagination["hydra:first"]).get("page");
            last = new URLSearchParams(action.pagination["hydra:last"]).get("page");
            return Object.assign({}, state, {
                threadsList: action.threads.map((thread) => thread.thread),
                threadsListPagination: {
                    first: first ? parseInt(first) : 1,
                    last: last ? parseInt(last) : 1,
                }
            });

        case "FETCH_MESSAGE_THREAD_SUCCEEDED":
            first = new URLSearchParams(action.pagination["hydra:first"]).get("page");
            last = new URLSearchParams(action.pagination["hydra:last"]).get("page");
            return Object.assign({}, state, {
                messages: action.messages,
                messagesPagination: {
                    first: first ? parseInt(first) : 1,
                    last: last ? parseInt(last) : 1,
                }
            });

        case "SEND_MESSAGE_SUCCEEDED":
            return Object.assign({}, state, {
                messages: [
                    action.message,
                    ...state.messages.slice(0, Math.max(state.messages.length - 1, 5))
                ],
            });

        case "CREATE_THREAD_SUCCEEDED":
            return Object.assign({}, state, {
                newlyCreatedThread: action.thread
            });

        case "FETCH_MESSAGE_THREAD_REQUESTED":
            return Object.assign({}, state, {
                newlyCreatedThread: null
            });
    }

    return state
}

export default reduc