import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./../GlobalStyle";
import * as Constants from "./../Constants"
import CommonStyle from "./CommonStyle";
import Grid from "@material-ui/core/Grid/Grid";
import React from "react";


export default makeStyles((theme) => {
    let CommonStyles = CommonStyle(theme);
    return {
        ...CommonStyles,
        threadsWrapper: {
            // width: Constants.GET_SIZE(1035),
            backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
            marginLeft: Constants.GET_SIZE(25),
            paddingLeft: Constants.GET_SIZE(50),
            paddingRight: Constants.GET_SIZE(50),
            paddingTop: Constants.GET_SIZE(50),
            paddingBottom: Constants.GET_SIZE(75),
            flex: 1,
            [theme.breakpoints.down('xs')]: {
                marginLeft: 0,
                padding: 10
            }
        },
        subcontentWrapper: {
            flex: 1
        },

        image: {
            width: Constants.GET_SIZE(100),
            height: Constants.GET_SIZE(100),
            marginRight: Constants.GET_SIZE(25),
        },
        imageShop: {
            borderRadius: "50%"
        },
        threadHeader: {
            ...GlobalStyle.row,
            marginLeft: Constants.GET_SIZE(25),
            marginBottom: Constants.GET_SIZE(40)
        },
        threadHeaderText: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        },
        threadHeaderText1: {
            marginBottom: Constants.GET_SIZE(5),

            fontSize: Constants.FONT_SIZE_MEDIUM,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
            }
        },
        threadHeaderText2: {

            color: Constants.FONT_GRAY
        },
        threadHeaderText3: {

            color: Constants.FONT_LIGHT_GRAY
        },
        threadWrapper: {
            ...GlobalStyle.row,
            maxWidth: Constants.GET_SIZE(900),
            height: Constants.GET_SIZE(100),
            backgroundColor: Constants.WHITE,
            borderColor: Constants.BORDER_GRAY,
            borderWidth: 1,
            paddingLeft: Constants.GET_SIZE(25),
            paddingRight: Constants.GET_SIZE(40),
            paddingTop: Constants.GET_SIZE(25),
            paddingBottom: Constants.GET_SIZE(10),
            marginBottom: Constants.GET_SIZE(25),
        },
        messageWrapper: {
            maxWidth: Constants.GET_SIZE(900),
            backgroundColor: Constants.WHITE,
            borderColor: Constants.BORDER_GRAY,
            borderStyle: "solid",
            borderWidth: 1,
            paddingLeft: Constants.GET_SIZE(25),
            paddingRight: Constants.GET_SIZE(40),
            paddingTop: Constants.GET_SIZE(25),
            paddingBottom: Constants.GET_SIZE(25),
            marginBottom: Constants.GET_SIZE(25),
            [theme.breakpoints.down('xs')]: {
                width: "100%",
                padding: 20,
                boxSizing: "border-box",
                alignItems: "center"
            }
        },
        threadWrapperLeft: {
            maxWidth: Constants.GET_SIZE(525),
            [theme.breakpoints.down('xs')]: {
                width: "100%"
            }
        },
        threadWrapperRight: {
            maxWidth: Constants.GET_SIZE(525),
            alignSelf: "flex-end",
            [theme.breakpoints.down('xs')]: {
                width: "100%"
            }
        },
        authorImage: {
            width: 50,
            height: 50,
            marginRight: 25,
            borderRadius: "50%",
            [theme.breakpoints.down('xs')]: {
                width: 30,
                height: 30,
                marginRight: 10,
            }
        },
        textWrapper: {
            // width: Constants.GET_SIZE(525)
        },
        authorName: {
            color: Constants.BLACK
            // marginBottom: Constants.GET_SIZE(10),
        },
        messagePreview: {

            color: Constants.FONT_GRAY,
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxHeight: 35
        },
        messageComplete: {
            color: Constants.FONT_GRAY,
            maxWidth: Constants.GET_SIZE(300),
            [theme.breakpoints.down('xs')]: {
                display: "none"
            }

        },
        messageCompleteMobile: {
            color: Constants.FONT_GRAY,
            marginTop: 15,
            width: "100%",
            [theme.breakpoints.up('sm')]: {
                display: "none"
            }

        },
        messageDate: {

            flex: 1,
            textAlign: "right"
        },

        sendMessageWrapper: {
            marginLeft: 25,
            marginTop: 20,
            [theme.breakpoints.down('xs')]: {
                marginLeft: 0,
            }
        },

        button: {
            ...GlobalStyle.button,
            width: Constants.GET_SIZE(300),
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: Constants.GET_SIZE(25),
            [theme.breakpoints.down('xs')]: {
                width: "100%"
            }
        },
        buttonLabel: {
            ...GlobalStyle.buttonLabel,
            textAlign: "center"
        },
        threadHeaderUnreadMessagesLabel: {

            marginRight: Constants.GET_SIZE(10)
        },
        threadHeaderUnreadMessages: {

            color: Constants.PINK
        },
        threadHeaderUnreadMessagesWrapper: {
            display: "flex",
            flexDirection: "row",
            marginLeft: Constants.GET_SIZE(200),
            [theme.breakpoints.down('xs')]: {
                marginLeft: 0,
                justifyContent: "center"
            }
        },

        threadsHeaderInputWrapper: {
            ...GlobalStyle.rowCentered,
            maxWidth: "min(350px, 90%)",
            height: 31,
            backgroundColor: "white",
            borderWidth: 2,
            borderColor: Constants.BORDER_GRAY,
            borderStyle: "solid",
            borderRadius: 45,
            paddingLeft: Constants.FONT_SIZE_NORMAL,
            paddingRight: Constants.FONT_SIZE_NORMAL,
            marginBottom: 50,
            marginTop: 25,
            marginLeft: 125,
            [theme.breakpoints.down('xs')]: {
                marginLeft: "auto",
                marginRight: "auto",
                boxSizing: "border-box",
                justifyContent: "center",
                width: "100%",
                marginBottom: 20,
            }
        },
        threadsHeaderInput: {
            marginLeft: 10,
            width: "100%"
        },
    }
});
