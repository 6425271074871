import React from "react";

import Box from "@material-ui/core/Box";

import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import StylesFunc from '../../Style/MiddleOffice/BaseStyle'

function Component({navigation, history, token, content, allowAnon}) {
    let Styles = StylesFunc();
    if (!allowAnon) {
        allowAnon = false;
    }
    React.useEffect(() => {
        console.log(allowAnon ? "allow" : "nallow", token ? "token" : "ntoken");
        if (!allowAnon && !token) {
            history.replace("/login", {redirectTo: history.location.pathname})
        }
    },[token]);

    return (
        <Box className={Styles.container}>
            {content}
        </Box>
    );
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
