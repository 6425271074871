import axios from 'axios';
import {API_URL} from '../../Config/API'
import { getApiUrl, setDefaultHeaders } from '../../Utils/QueryUtils'

export const SHOP_TYPES = {
    "createurs": 2,
    "bloggers": 3,
    "responsables": 1
};

export const FetchShop = (id) => {
    return axios.get(API_URL + 'api/shops/' + id, {
        headers: setDefaultHeaders()
    })
};

export const FetchProductShop = (id) => {
    return axios.get(API_URL + 'api/products/' + id + "/shop", {
        headers: setDefaultHeaders()
    })
};

export const FetchShops = () => {
    return axios.get(API_URL + 'api/shops', {
        headers: setDefaultHeaders()
    })
};

export const SearchShops = (page, type, name, itemsPerPage) => {
    let url = API_URL + 'api/shops?';

    url += "page=" + page;

    if (type !== null) {
        if (SHOP_TYPES[type]) {
            url += "&type=" + SHOP_TYPES[type];
        }
    }

    if (name) {
        url += "&name=" + name;
    }

    if (itemsPerPage) {
        url += "&itemsPerPage=" + itemsPerPage;
    }

    url += '&orderByLastProductAdded=1';

    return axios.get(url, {
        headers: setDefaultHeaders()
    })
};

export const FetchLovedShops = () => {
    return axios.get(API_URL + 'api/shops/loved', {
        headers: setDefaultHeaders()
    })
};

export const FetchSubscribedShops = (page, itemsPerPage) => {
    return axios.get(getApiUrl('shops/my_subscriptions', {page, itemsPerPage}), {
        headers: setDefaultHeaders()
    })
};

export const SubscribeToShop = (id) => {
    return axios.get(API_URL + 'api/shops/' + id + '/subscribe', {
        headers: setDefaultHeaders()
    })
};

export const UnsubscribeFromShop = (id) => {
    return axios.get(API_URL + 'api/shops/' + id + '/unsubscribe', {
        headers: setDefaultHeaders()
    })
};

export const SaveShop = (shop) => {
    if (shop.id) {
        return axios.put(API_URL + 'api/shops/' + shop.id, shop,  {
            headers: setDefaultHeaders()
        })
    } else {
        return axios.post(API_URL + 'api/shops', shop,  {
            headers: setDefaultHeaders()
        })
    }
};

export const EvaluateShop = (shopId, note) => {
    return axios.get(getApiUrl('shops/' + shopId + '/evaluate', {note}),  {
        headers: setDefaultHeaders()
    })
};

export const ValidateAddress = (city, zipcode, address) => {
    return axios.get(getApiUrl('api/shop/validate_address', {city, zipcode, address}),  {
        headers: setDefaultHeaders()
    })
};

export const DeleteShop = (id) => {
    return axios.delete(API_URL + 'api/shops/' + id, {
        headers: setDefaultHeaders()
    })
};
