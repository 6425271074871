import React from "react";
import clsx from 'clsx';
import { Image, ScrollView } from "react-native";
import { TouchableOpacity } from 'react-native-web';

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Text from "../Component/FontText";
import CustomCheckbox from "../Component/CustomCheckbox";
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import {useForm} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";

import RelaySelectionModal from "./Component/RelaySelectionModal"
import AddressSelectionModal from "./Component/AddressSelectionModal"
import ChronopostLogo from "../../Assets/Images/chronopost_logo.svg";
import PointRelaisLogo from "../../Assets/Images/pointrelais_logo.svg";
import ClickAndCollectLogo from "../../Assets/Images/clickandcollect_logo.svg";
import HandsLogo from "../../Assets/Images/hand_logo.svg";
import TruckImage from "../../Assets/Images/truck.svg";
import ClockImage from "../../Assets/Images/clock.svg";
import CreditCardImage from "../../Assets/Images/credit_card.svg";
import ActiveTruckImage from "../../Assets/Images/truck_active.svg";
import ActiveClockImage from "../../Assets/Images/clock_active.svg";
import ActiveCreditCardImage from "../../Assets/Images/credit_card_active.svg";

import { CardCvcElement, CardNumberElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js';
import ErrorStyleFunc from "../Styles/Error";
import {InternalLink, LINKS} from "../Component/InternalLink";
import Moment from "moment";

import BuyingTunnelStylesFunc from "../../Style/BuyingTunnelStyle"
import * as Constants from "../../Style/Constants"
import Link from "@material-ui/core/Link/Link";

import {FetchProduct} from "../../API/Endpoint/Product";
import {getPurchaseDeliveryPrice} from "../../Utils/PriceUtils";
import CircularProgress from '@material-ui/core/CircularProgress';


function Component({history, navigation, token, cart, postPurchase, purchase, fetchPointRelais, me, emptyCart, fetchMe, removeProductFromCart, shippingPrices, params}) {
    let BuyingTunnelStyles = BuyingTunnelStylesFunc();
    let ErrorStyle = ErrorStyleFunc();
    let defaultAddress = null;
    if (me && me.firstname && me.name && me.civility && me.phone && me.shippingAddress && me.shippingZipCode && me.shippingCity) {
        defaultAddress = {
            "firstname": me.firstname,
            "name": me.name,
            "gender": me.civility,
            "phone": me.phone,
            "address": me.shippingAddress,
            "zipcode": me.shippingZipCode,
            "city": me.shippingCity
        }
    }

    const { setError, clearErrors, control, errors, watch, register, setValue } = useForm();
    let [chronopostRelayVisible, setChronopostRelayVisible] = React.useState(false);
    let [chronopostRelay, setChronopostRelay] = React.useState(null);
    let [mondialRelayVisible, setMondialRelayVisible] = React.useState(false);
    let [mondialRelay, setMondialRelay] = React.useState(null);
    let [addressModalVisible, setAddressModalVisible] = React.useState(false);
    let [address, setAddress] = React.useState(defaultAddress);
    let [page, setPage] = React.useState(0);
    let [cgv, setCGV] = React.useState(false);
    let [selectedCard, setSelectedCard] = React.useState(-1);
    let [memorizeCard, setMemorizeCard] = React.useState(false);

    let [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        if (page === 2) {
            fetchMe(token);
        }
        window.scrollTo(0, 0);
    }, []);

    React.useEffect(() => {
        if (me && me.firstname && me.name && me.civility && me.phone && me.shippingAddress && me.shippingZipCode && me.shippingCity) {
            setAddress({
                "firstname": me.firstname,
                "name": me.name,
                "gender": me.civility,
                "phone": me.phone,
                "address": me.shippingAddress,
                "zipcode": me.shippingZipCode,
                "city": me.shippingCity
            })
        }
    }, [me]);

    const stripe = useStripe();
    const elements = useElements();

    let postPurchaseData = () => {
        const productsPurchasesData = [];
        for (const item of cart) {
            productsPurchasesData.push({
                "product": "/api/products/" + item["product"]["id"],
                "quantity": parseInt(item.quantity),
                "deliveryType": parseInt(item.delivery),
                "color": item.color["@id"],
                "size": item.size ? item.size["@id"] : null
            })
        }
        postPurchase(me, productsPurchasesData, address, address, mondialRelay ? mondialRelay["id"] : null, chronopostRelay ? chronopostRelay["id"] : null);
    };

    React.useEffect(() => {
        if (cart.length > 0) {
            postPurchaseData()
        }
    }, [cart]);

    let onLoadUser = () => {

    }


    let productPrice = 0;
    const deliveryPrice = getPurchaseDeliveryPrice(cart, shippingPrices, params);;

    for (const item of cart) {
        productPrice += item.quantity * item.product.realPrice;
    }

    let hasChronoDelivery = false;
    let hasChronoRelay = false;
    let hasMondialRelay = false;
    let hasClickAndCollect = false;
    let hasHands = false;

    for (const item of cart) {
        if (item.delivery === 0) {
            hasChronoDelivery = true;
        } else if (item.delivery === 1) {
            hasChronoRelay = true;
        } else if (item.delivery === 2) {
            hasMondialRelay = true;
        } else if (item.delivery === 3) {
            hasClickAndCollect = true;
        } else if (item.delivery === 4) {
            hasHands = true;
        }
    }


    const submitForm = async () => {

        // We first check if product is still available
        let nbQueries = purchase.purchaseProducts.length;
        let productSoldOut = false;
        for (const purchaseProduct of purchase.purchaseProducts) {
            FetchProduct(purchaseProduct.product.id).then((result) => {
                if (result.data.quantity === 0) {
                    // One product is not available anymore, redirect to cart
                    removeProductFromCart(purchaseProduct.product);
                    productSoldOut = true;
                }
                nbQueries--;
            })
        }

        while (nbQueries > 0) {
            await new Promise(r => setTimeout(r, 100));
        }
        if (productSoldOut === true) {
            history.push("/cart", {error: "product_sold_out"});
        } else {

            // We don't want to let default form submission happen here,
            // which would refresh the page.
            // event.preventDefault();

            if (!stripe || !elements || !purchase) {
                // Stripe.js has not yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return;
            }

            let data = {};

            if (memorizeCard) {
                data["setup_future_usage"] = 'on_session';
            }

            if (selectedCard !== -1) {
                data["payment_method"] = me.savedCardId[selectedCard];
            } else {
                data["payment_method"] = {
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        name: me.username
                    }
                }
            }

            console.log(data);

            const result = await stripe.confirmCardPayment(purchase.stripeClientSecret, data);

            if (result.error) {
                // Show error to your customer (e.g., insufficient funds)
                setError("card", {type: "invalid", message: result.error.message});
            } else {
                // The payment has been processed!
                if (result.paymentIntent.status === 'succeeded') {
                    for (const purchaseProduct of cart) {
                        removeProductFromCart(purchaseProduct.product);
                    }
                    emptyCart();
                    setPage(2);
                    // Show a success message to your customer
                    // There's a risk of the customer closing the window before callback
                    // execution. Set up a webhook or plugin to listen for the
                    // payment_intent.succeeded event that handles any business critical
                    // post-payment actions.
                }
            }
            setIsLoading(false);
        }
    };

    let getHeader = () => {
        return (
            <Box>
                <Box className={BuyingTunnelStyles.headerBar}>
                    <Box className={clsx(BuyingTunnelStyles.row, {[BuyingTunnelStyles.rowInactive]: page !== 0})}>
                        <Image source={page === 0 ? ActiveTruckImage : TruckImage} className={BuyingTunnelStyles.headerTruckImage}/>
                        <Text className={clsx(BuyingTunnelStyles.headerBarText, {[BuyingTunnelStyles.headerBarTextActive]: page === 0})}>
                            Livraison
                        </Text>
                    </Box>
                    <Box className={clsx(BuyingTunnelStyles.row, {[BuyingTunnelStyles.rowInactive]: page !== 1})}>
                        <Image source={page === 1 ? ActiveCreditCardImage : CreditCardImage} className={BuyingTunnelStyles.headerCardImage}/>
                        <Text className={clsx(BuyingTunnelStyles.headerBarText, {[BuyingTunnelStyles.headerBarTextActive]: page === 1})}>
                            Paiement
                        </Text>
                    </Box>
                    <Box className={clsx(BuyingTunnelStyles.row, {[BuyingTunnelStyles.rowInactive]: page !== 2})}>
                        <Image source={page === 2 ? ActiveClockImage : ClockImage} className={BuyingTunnelStyles.headerClockImage} />
                        <Text className={clsx(BuyingTunnelStyles.headerBarText, {[BuyingTunnelStyles.headerBarTextActive]: page === 2})}>
                            Confirmation
                        </Text>
                    </Box>
                </Box>
            </Box>
        );
    };

    let getChronopostAddressPicker = () => {
        return (
            <Grid container className={BuyingTunnelStyles.addressPickerWrapper}>
                <Image source={ChronopostLogo} className={BuyingTunnelStyles.chronopostLogo} />
                <Box className={BuyingTunnelStyles.deliveryNameWrapper}>
                    <Text className={BuyingTunnelStyles.deliveryName}>Chronopost domicile</Text>
                    <Text className={BuyingTunnelStyles.deliveryName2}>Choisir une adresse</Text>
                </Box>
                <Box className={BuyingTunnelStyles.deliveryAddressWrapper}>
                    {address && <Text className={BuyingTunnelStyles.deliveryName}>{address.firstname} {address.name}</Text>}
                    {address && <Text className={BuyingTunnelStyles.deliveryName2}>{address.address}</Text>}
                    {address && <Text className={BuyingTunnelStyles.deliveryName2}>{address.zipcode} {address.city}</Text>}
                </Box>
                <Button onClick={() => setAddressModalVisible(true)} variant={"contained"} color={"primary"} disableElevation size={"small"}>
                    {address ? "Modifier l'adresse" : "Ajouter une adresse"}
                </Button>
            </Grid>
        );
    };

    let getChronopostRelayPicker = () => {
        return (
            <Grid container className={BuyingTunnelStyles.addressPickerWrapper}>
                <Image source={ChronopostLogo} className={BuyingTunnelStyles.chronopostLogo} />
                <Box className={BuyingTunnelStyles.deliveryNameWrapper}>
                    <Text className={BuyingTunnelStyles.deliveryName}>Chronopost point relay</Text>
                    <Text className={BuyingTunnelStyles.deliveryName2}>Choisir un point relay</Text>
                </Box>
                <Box className={BuyingTunnelStyles.deliveryAddressWrapper}>
                    {chronopostRelay && <Text className={BuyingTunnelStyles.deliveryName}>{chronopostRelay.name}</Text>}
                    {chronopostRelay && <Text className={BuyingTunnelStyles.deliveryName2}>{chronopostRelay.address1}</Text>}
                    {chronopostRelay && <Text className={BuyingTunnelStyles.deliveryName2}>{chronopostRelay.zipCode} {chronopostRelay.city}</Text>}
                </Box>
                <Button onClick={() => setChronopostRelayVisible(true)} variant={"contained"} color={"primary"} disableElevation size={"small"}>
                    Choisir un relay
                </Button>
            </Grid>
        );

    };

    let getMondialRelayPicker = () => {
        return (
            <Grid container className={BuyingTunnelStyles.addressPickerWrapper}>
                <Image source={PointRelaisLogo} className={BuyingTunnelStyles.mondialRelayLogo} />
                <Box className={BuyingTunnelStyles.deliveryNameWrapper}>
                    <Text className={BuyingTunnelStyles.deliveryName}>Mondial relay</Text>
                    <Text className={BuyingTunnelStyles.deliveryName2}>Choisir un point relay</Text>
                </Box>
                <Box className={BuyingTunnelStyles.deliveryAddressWrapper}>
                    {mondialRelay && <Text className={BuyingTunnelStyles.deliveryName}>{mondialRelay.name}</Text>}
                    {mondialRelay && <Text className={BuyingTunnelStyles.deliveryName2}>{mondialRelay.address1}</Text>}
                    {mondialRelay && <Text className={BuyingTunnelStyles.deliveryName2}>{mondialRelay.zipCode} {mondialRelay.city}</Text>}
                </Box>
                <Button onClick={() => setMondialRelayVisible(true)} variant={"contained"} color={"primary"} disableElevation size={"small"}>
                    Choisir un relay
                </Button>
                {/*<Box>*/}
                    {/*<TouchableOpacity*/}
                        {/*onPress={() => setMondialRelayVisible(true)}*/}
                        {/*className={BuyingTunnelStyles.button}>*/}
                        {/*<Text className={BuyingTunnelStyles.buttonLabel}>Choisir un relay</Text>*/}
                    {/*</TouchableOpacity>*/}
                {/*</Box>*/}
            </Grid>
        );

    };

    let getClickAndCollect = () => {
        let clickAndCollectLabel = "";
        for (const product of cart) {
            if (product.delivery === 3) {
                let clickAndCollectEvents = [];
                for (const event of product.product.shop.events) {
                    clickAndCollectEvents.push("le " + Moment(event.datetime).locale('fr').format('DD/MM/YYYY') + " à " + event.city);
                }
                clickAndCollectLabel = clickAndCollectEvents.join(" ou ");
            }
        }
        return (
            <Box className={BuyingTunnelStyles.addressPickerWrapper}>
                <Image source={ClickAndCollectLogo} className={BuyingTunnelStyles.clickAndCollectLogo} />
                <Box className={BuyingTunnelStyles.deliveryNameWrapper}>
                    <Text className={BuyingTunnelStyles.deliveryName}>Click & Collect</Text>
                    <Text className={BuyingTunnelStyles.deliveryName2}>{clickAndCollectLabel}</Text>
                </Box>
            </Box>
        );

    };

    let getHands = () => {
        return (
            <Box className={BuyingTunnelStyles.addressPickerWrapper}>
                <Image source={HandsLogo} className={BuyingTunnelStyles.handsLogo} />
                <Box className={BuyingTunnelStyles.deliveryNameWrapper}>
                    <Text className={BuyingTunnelStyles.deliveryName}>Remise en main propre</Text>
                    <Text className={BuyingTunnelStyles.deliveryName2}>Vous recevrez un mail avec toutes les instructions afin de contacter le vendeur et récupérer votre achat.</Text>
                </Box>
            </Box>
        );

    };

    let getCart = () => {
        let nbTotalProducts = 0;
        for (const p of cart) {
            nbTotalProducts += parseInt(p.quantity);
        }

        return (
            <Box style={{marginTop: Constants.GET_SIZE(50)}}>
                <Text className={BuyingTunnelStyles.shoppingCartTitle}>MON PANIER</Text>
                <Box className={clsx(BuyingTunnelStyles.shoppingCartBlock, BuyingTunnelStyles.shoppingCartBlockFirst)}>
                    <Box className={BuyingTunnelStyles.shoppingCartRow}>
                        <Text className={BuyingTunnelStyles.shoppingCartText}>{nbTotalProducts} ARTICLE{nbTotalProducts > 1 ? "S" : ""}</Text>
                        <Text className={BuyingTunnelStyles.shoppingCartText}>{productPrice.toFixed(2)} €</Text>
                    </Box>
                    <Box className={BuyingTunnelStyles.shoppingCartRow}>
                        <Text className={BuyingTunnelStyles.shoppingCartText}>LIVRAISON{nbTotalProducts > 1 ? "S" : ""} </Text>
                        <Text className={BuyingTunnelStyles.shoppingCartText}>{deliveryPrice.toFixed(2)} €</Text>
                    </Box>
                </Box>
                <Box className={clsx(BuyingTunnelStyles.shoppingCartBlock, BuyingTunnelStyles.shoppingCartBlockLast)}>
                    <Box className={BuyingTunnelStyles.shoppingCartRow}>
                        <Text className={BuyingTunnelStyles.shoppingCartTotalText}>TOTAL</Text>
                        <Text className={BuyingTunnelStyles.shoppingCartTotalText}>{(deliveryPrice + productPrice).toFixed(2)} €</Text>
                    </Box>
                </Box>
                <Button variant={"contained"} color={"primary"} disableElevation onClick={onClickContinue} disabled={cart.length === 0} className={BuyingTunnelStyles.shoppingCartButtonWrapper}>
                    {isLoading ? <CircularProgress size={25} color={"white"} /> : "Continuer"}
                </Button>
            </Box>
        );
    };

    let getPage1 = () => {
        let hasClickAndCollectOrHandsOver = false;
        let pageTitle = "";
        if (purchase) {
            for (const purchaseProduct of purchase.purchaseProducts) {
                if (purchaseProduct.deliveryType === 3 || purchaseProduct.deliveryType === 4) {
                    hasClickAndCollectOrHandsOver = true;
                }
            }

            if (hasClickAndCollectOrHandsOver) {
                pageTitle = "Choisissez la manière dont vous souhaitez recevoir vos produits";
            } else {
                pageTitle = "Choisissez votre adresse de livraison";
            }
        } else if (cart) {
            for (const purchaseProduct of cart) {
                if (purchaseProduct.delivery === 3 || purchaseProduct.delivery === 4) {
                    hasClickAndCollectOrHandsOver = true;
                }
            }

            if (hasClickAndCollectOrHandsOver) {
                pageTitle = "Choisissez la manière dont vous souhaitez recevoir vos produits";
            } else {
                pageTitle = "Choisissez votre adresse de livraison";
            }
        }

        return (
            <Box className={BuyingTunnelStyles.wrapper}>
                <Text className={BuyingTunnelStyles.pageTitle}>{pageTitle}</Text>

                {hasChronoDelivery && getChronopostAddressPicker()}
                <ErrorMessage name={"chronoDelivery"} errors={errors} as={Text} className={clsx(BuyingTunnelStyles.error, ErrorStyle.error)} />
                {hasChronoRelay && getChronopostRelayPicker()}
                <ErrorMessage name={"chronoRelay"} errors={errors} as={Text} className={clsx(BuyingTunnelStyles.error, ErrorStyle.error)} />
                {hasMondialRelay && getMondialRelayPicker()}
                <ErrorMessage name={"mondialRelay"} errors={errors} as={Text} className={clsx(BuyingTunnelStyles.error, ErrorStyle.error)} />
                <Box className={BuyingTunnelStyles.horizontalDivider} />
                <Box className={BuyingTunnelStyles.row}>
                    {hasClickAndCollect && getClickAndCollect()}
                    {hasClickAndCollect && hasHands && <Box style={{width: 20}} />}
                    {hasHands && getHands()}
                </Box>
            </Box>
        );
    };


    const createOptions = (fontSize, padding) => {
        return {
            style: {
                base: {
                    fontSize,
                    color: 'black',
                    letterSpacing: '0.025em',
                    fontFamily: 'Source Code Pro, monospace',
                    ...(padding ? {padding} : {}),
                },
                invalid: {
                    color: '#CA8A8F',
                },
            },
            hidePostalCode: true
        };
    };

    let savedCardsDom = [];
    if (me && me.savedCard) {
        for (let i = 0; i < me.savedCard.length; i++) {
            savedCardsDom.push(
                <CustomCheckbox
                    size={Constants.CHECKBOX_SIZE_BIG}
                    containerStyle={{marginBottom: Constants.GET_SIZE(10)}}
                    containerClass={BuyingTunnelStyles.checkboxContainer}
                    checked={selectedCard === i}
                    title={<Text className={BuyingTunnelStyles.checkboxLabel}>Carte enregistrée **** {me.savedCard[i]}</Text>}
                    checkedColor={Constants.PINK}
                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                    checkedIcon={"square"}
                    onPress={() => {setSelectedCard(i)}}
                />
            )
        }
    }

    let getPage2 = () => {
        return (
            <Box className={BuyingTunnelStyles.wrapper}>
                <CustomCheckbox
                    containerClass={BuyingTunnelStyles.checkboxContainer}
                    checkedColor={Constants.PINK}
                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                    title={<Text className={clsx(BuyingTunnelStyles.checkboxLabel, {[ErrorStyle.error]: errors["cgv"]})}>J'accepte les <Link target="_blank" href={LINKS["STATIC_CGV"]["web"]} style={{width: "max-content", color: Constants.PINK, textDecoration: "underline", textDecorationColor: Constants.PINK}}>
                        conditions générales de ventes.*
                    </Link></Text>}
                    checked={cgv}
                    onPress={() => {clearErrors("cgv"); setCGV(!cgv)}}
                    size={"large"}
                    error={errors['cgv']}
                />
                {/*<ErrorMessage name={"cgv"} errors={errors} as={Text} className={clsx(BuyingTunnelStyles.error, ErrorStyle.error)} />*/}

                {savedCardsDom}
                <CustomCheckbox
                    size={Constants.CHECKBOX_SIZE_BIG}
                    containerClass={BuyingTunnelStyles.checkboxContainer}
                    checked={selectedCard === -1}
                    title={<Text className={BuyingTunnelStyles.checkboxLabel}>Ajouter une autre carte</Text>}
                    checkedColor={Constants.PINK}
                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                    checkedIcon={"square"}
                    onPress={() => {setSelectedCard(-1)}}
                />
                {selectedCard === -1 &&
                <Box className={BuyingTunnelStyles.stripeFieldWrapper}>
                    <CardNumberElement options={createOptions(Constants.FONT_SIZE_MEDIUM)} />
                </Box>}
                {selectedCard === -1 &&<Box className={BuyingTunnelStyles.stripeFieldWrapper}>
                    <CardExpiryElement options={createOptions(Constants.FONT_SIZE_MEDIUM)} />
                </Box>}
                {selectedCard === -1 &&<Box className={BuyingTunnelStyles.stripeFieldWrapper}>
                    <CardCvcElement options={createOptions(Constants.FONT_SIZE_MEDIUM)} />
                </Box>}
                <ErrorMessage name={"card"} errors={errors} as={Text} className={ErrorStyle.error} />
                {selectedCard === -1 &&<CustomCheckbox
                    containerClass={BuyingTunnelStyles.checkboxContainer}
                    checkedColor={Constants.PINK}
                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                    title={<Text className={BuyingTunnelStyles.checkboxLabel}>Mémoriser cette carte</Text>}
                    checked={memorizeCard}
                    onPress={() => setMemorizeCard(!memorizeCard)}
                    size={Constants.CHECKBOX_SIZE_BIG}
                />}
            </Box>
        );
    };

    let getPage3 = () => {
        return (
            <Box style={{alignItems: "center", maxWidth: 800, margin: "auto", textAlign: "center"}}>
                <Text className={BuyingTunnelStyles.confirmationMessage}>Félicitations, ta commande a bien été prise en compte !</Text>
                <Text className={BuyingTunnelStyles.confirmationMessage2}>Un mail te sera transmis avec le récapitulatif de ta commande</Text>
                <Text className={BuyingTunnelStyles.confirmationMessage3}>A très vite !</Text>

                <Button variant={"contained"} color={"primary"} disableElevation href={"/"} className={BuyingTunnelStyles.confirmationMessageButton}>
                    Retourner shopper
                </Button>
            </Box>
        )
    };

    let onClickContinue = () => {
        if (page === 0) {
            let hasError = false;
            if (hasChronoDelivery && !address) {
                setError("chronoDelivery", {type: "invalid", message: "Vous devez choisir une adresse de livraison."})
                hasError = true;
            } else {
                clearErrors("chronoDelivery");
            }
            if (hasChronoRelay && !chronopostRelay) {
                setError("chronoRelay", {type: "invalid", message: "Vous devez choisir point relais Chronopost."})
                hasError = true;
            } else {
                clearErrors("chronoRelay");
            }
            if (hasMondialRelay && !mondialRelay) {
                setError("mondialRelay", {type: "invalid", message: "Vous devez choisir point relais Mondial Relay."})
                hasError = true;
            } else {
                clearErrors("mondialRelay");
            }
            if (!hasError) {
                postPurchaseData();
                // const productsPurchasesData = [];
                // for (const item of cart) {
                //     productsPurchasesData.push({
                //         "product": "/api/products/" + item["product"]["id"],
                //         "quantity": item["quantity"],
                //         "deliveryType": item["delivery"]
                //     })
                // }

                setPage(1);
            }
        } else if (page === 1) {
            if (!cgv) {
                setError("cgv", {type: "invalid", message: "Vous devez accepter les conditions générales de vente pour continuer"});
            } else {
                setIsLoading(true);
                submitForm();
            }
        }
    };

    return (
        <Box>
            <Grid container className={BuyingTunnelStyles.container}>
                <Box className={BuyingTunnelStyles.leftContent}>
                    {getHeader(0)}
                    {page === 0 && getPage1()}
                    {page === 1 && getPage2()}
                    {page === 2 && getPage3()}
                </Box>
                {page !== 2 &&
                    <Box className={BuyingTunnelStyles.shoppingCartWrapper}>
                        {getCart()}
                    </Box>
                }
            </Grid>
            <RelaySelectionModal visible={chronopostRelayVisible} setVisible={setChronopostRelayVisible} setValue={setChronopostRelay} type={"Chronopost"} />
            <RelaySelectionModal visible={mondialRelayVisible} setVisible={setMondialRelayVisible} setValue={setMondialRelay} type={"MondialRelay"} />
            {me && <AddressSelectionModal visible={addressModalVisible} setVisible={setAddressModalVisible} setFullAddress={setAddress} defaultAddress={{
                gender: me.civility,
                address: me.facturationAddress,
                zipcode: me.facturationZipCode,
                city: me.facturationCity,
                name: me.name,
                firstname: me.firstname,
                phone: me.phone,
            }} />}
        </Box>
    );
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        cart: state.product.cart,
        purchase: state.purchase.purchase,
        me: state.user.me,
        shippingPrices: state.family.shippingPrices,
        params: state.params.params
    }
};

const mapDispatchToProps = dispatch => {
    return {
        postPurchase: (user, purchaseProducts, deliveryAddress, facturationAddress, mondialRelayId, chronopostRelayId) => {
            dispatch({type: 'POST_PURCHASE_REQUESTED', user, purchaseProducts, deliveryAddress, facturationAddress, mondialRelayId, chronopostRelayId})
        },
        fetchPointRelais: () => {
            dispatch({type: 'FETCH_POINT_RELAIS_REQUESTED'})
        },
        emptyCart: () => {
            dispatch({type: 'EMPTY_CART'})
        },
        fetchMe: (token) => {
            dispatch({type: 'FETCH_ME_REQUESTED', payload: {token}})
        },
        removeProductFromCart: (product) => {
            dispatch({type: 'REMOVE_FROM_CART', product})
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
