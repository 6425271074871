import React from "react";
import clsx from 'clsx';
import {Animated, Easing} from "react-native";

import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";

import { TouchableOpacity, Image } from "react-native-web";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { FontAwesome } from '../../FontAwesome';
import { useParams } from "react-router-dom";

import Text from "../Component/FontText"
import LeftMenu from "./Partial/LeftMenu"
import Base from "./Base"
import StylesFunc from "../../Style/MiddleOffice/MessagingStyle"
import * as Constants from "../../Style/Constants"
import Paginator from "../Front/Component/Paginator";
import {getImageUrl} from "../../Utils/ImageUtils";
import Moment from "moment";
import UserIcon from "../../Assets/Images/user_icon.svg"
import TextInputIcon from "../Front/Component/TextInputIcon";
import {useForm} from "react-hook-form";


function Component({navigation, history, token, user, messages, pagination, fetchedProduct, fetchedShop, newlyCreatedThread, fetchThread, sendMessage, createThread, fetchProduct, fetchShop, cleanMessagesList, isLoadingMessage}) {
    let Styles = StylesFunc();
    const { setValue, setError, handleSubmit, register, errors, watch } = useForm();
    let { id } = useParams();
    let shopId = null;
    let productId = null;
    let recipient = null;
    if (id === "nouveau") {
        if (history.location.state && history.location.state.shopId) {
            shopId = history.location.state.shopId;
        } else if (history.location.state && history.location.state.productId) {
            productId = history.location.state.productId;
        }
        if (history.location.state && history.location.state.recipient) {
            recipient = history.location.state.recipient;
        }
    }
    let [page, setPage] = React.useState(1);
    let [spinValue] = React.useState(new Animated.Value(0));

    React.useEffect(() => {
        if (id !== "nouveau") {
            fetchThread(id, page, 6);
        } else if (productId !== null) {
            cleanMessagesList();
            fetchProduct(productId);
        } else if (shopId !== null) {
            cleanMessagesList();
            fetchShop(shopId);
        }
    }, [id, token, page]);

    React.useEffect(() => {
        if (page > pagination.last) {
            setPage(pagination.last);
        } else if (page < pagination.first) {
            setPage(pagination.first);
        }
    }, [pagination]);

    React.useEffect(() => {
        if (id === "nouveau" && newlyCreatedThread !== null) {
            history.push("/message/" + newlyCreatedThread.id);
        }
    }, [newlyCreatedThread]);

    let handleSendMessage = ({message}) => {
        if (id !== "nouveau") {
            sendMessage(id, message, page, 6);
        } else {
            createThread(productId ? product : null, shopId ? shop : null, message, recipient !== null ? recipient['@id'] : null);
        }
        setValue("message", "");
    };

    let otherPerson = null;
    let thread = null;
    let product = null;
    let shop = null;

    if (messages && messages.length > 0) {
        thread = messages[0].thread;
        otherPerson = thread.author.id === user.id ? thread.recipient : thread.author;
        product = thread.product;
        shop = thread.shop;
    } else if (productId !== null && fetchedProduct) {
        otherPerson = fetchedProduct.shop.owner;
        product = fetchedProduct;
    } else if (shopId !== null && fetchedShop) {
        otherPerson = fetchedShop.owner;
        shop = fetchedShop;
    }

    let _getMessageBlock = (message) => {
        let amAuthor = (thread.author.id === user.id && message.isAuthor) || (thread.author.id !== user.id && !message.isAuthor);
        let messageAuthorName;
        if (amAuthor) {
            messageAuthorName = user.firstname && user.name ? user.firstname + " " + user.name : user.username;
        } else {
            messageAuthorName = otherPerson.firstname && otherPerson.name ? otherPerson.firstname + " " + otherPerson.name : otherPerson.username;
        }

        let authorShop = null;
        if (!amAuthor && otherPerson.shop) {
            authorShop = otherPerson.shop;
        } else if (amAuthor && user.shop) {
            authorShop = user.shop;
        }

        return <Grid container className={clsx(Styles.messageWrapper, {[Styles.threadWrapperLeft]: amAuthor}, {[Styles.threadWrapperRight]: amAuthor})}>
            {!authorShop && <Image source={UserIcon} className={Styles.authorImage} />}
            {authorShop && <Link href={"/shop/" + authorShop.id}><Image source={UserIcon} className={Styles.authorImage} /></Link>}
            <Box className={Styles.textWrapper}>
                {!authorShop && <Text className={Styles.authorName}>{messageAuthorName}</Text>}
                {authorShop && <Link href={"/shop/" + authorShop.id}><Text className={Styles.authorName}>{messageAuthorName}</Text></Link>}
                <Text className={Styles.messageComplete} style={message.isNewMessage ? {fontWeight: "bold"} : {}}>{message.content}</Text>
            </Box>
            <Text className={Styles.messageDate}>{Moment(message.createdAt).format("DD/MM/YY")}</Text>
            <Text className={Styles.messageCompleteMobile} style={message.isNewMessage ? {fontWeight: "bold"} : {}}>{message.content}</Text>
        </Grid>
    };

    Animated.loop(
        Animated.timing(
            spinValue,
            {
                toValue: 1,
                duration: 1500,
                easing: Easing.linear
            }
        ),
        {
            iterations: -1
        }
    ).start();

    const spin = spinValue.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '360deg']
    });

    return <Base content={
        <Box className={Styles.container}>
            <Text className={Styles.title}>Messagerie</Text>

            <Grid container>
                <Hidden xsDown>
                    <LeftMenu currentPage={"MESSAGE_THREADS"} wrapperStyle={{marginTop: Constants.GET_SIZE(90)}}/>
                </Hidden>
                <Box className={Styles.subcontentWrapper}>
                    <TouchableOpacity className={Styles.threadHeader} onPress={() => product !== null ? history.push("/product/" + product.id) : history.push("/shop/" + shop.id)}>
                        {product !== null && <Image source={getImageUrl(product.pictures[0].contentUrl, "small")} className={clsx([Styles.image, Styles.imageProduct])} />}
                        {shop !== null && <Image source={shop.picture ? getImageUrl(shop.picture.contentUrl, "small") : null    } className={clsx([Styles.image, Styles.imageShop])} />}
                        <Box className={Styles.threadHeaderText}>
                            {recipient !== null && <Text className={Styles.threadHeaderText1}>{recipient.firstname && recipient.name ? recipient.firstname + " " + recipient.name : recipient.username}</Text>}
                            {recipient === null && <Text className={Styles.threadHeaderText1}>{otherPerson !== null && (otherPerson.firstname && otherPerson.name ? otherPerson.firstname + " " + otherPerson.name : otherPerson.username)}</Text>}
                            {product !== null && <Text className={Styles.threadHeaderText2}>{product.name}</Text>}
                            {shop !== null && <Text className={Styles.threadHeaderText2}>{shop.name}</Text>}
                            <Text className={Styles.threadHeaderText3}>{otherPerson !== null && ("Membre depuis le " + Moment(otherPerson.createdAt).format("DD/MM/YY"))}</Text>
                        </Box>
                    </TouchableOpacity>
                    <Box className={Styles.threadsWrapper}>
                        {messages.slice().reverse().map(_getMessageBlock)}
                    </Box>
                    <Box className={Styles.sendMessageWrapper}>
                        <TextInputIcon
                            errors={errors}
                            register={register}
                            placeholder={"Ecrire un message"}
                            rules={{
                                required: "Ce champ est obligatoire",
                            }}
                            name={"message"}
                            multiline={true}
                        />
                        <TouchableOpacity className={Styles.button} style={{flexDirection: "row"}} onPress={handleSubmit(handleSendMessage)}>
                            <Text className={Styles.buttonLabel} style={{marginRight: Constants.GET_SIZE(10)}}>Envoyer</Text>
                            {isLoadingMessage &&
                                <Animated.View
                                    style={{
                                        transform: [{rotate: spin}]
                                    }}>
                                    <FontAwesome name={"spinner"} color={"white"} style={{fontSize: Constants.FONT_SIZE_NORMAL}}/>
                                </Animated.View>
                            }
                        </TouchableOpacity>
                    </Box>
                </Box>
            </Grid>
            {pagination && <Paginator firstPage={pagination.first} lastPage={pagination.last} page={page} setPage={setPage} backgroundColor={Constants.WHITE}/>}
        </Box>
    } />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.me,
        fetchedProduct: state.product.currentProduct,
        fetchedShop: state.shop.currentShop,
        messages: state.message.messages,
        pagination: state.message.messagesPagination,
        newlyCreatedThread: state.message.newlyCreatedThread,
        isLoadingMessage: state.loading.isLoadingMessage
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchThread: (id, page, itemsPerPage) => {
            dispatch({type: 'FETCH_MESSAGE_THREAD_REQUESTED', id, page, itemsPerPage})
        },
        sendMessage: (id, message, page, itemsPerPage) => {
            dispatch({type: 'SEND_MESSAGE_REQUESTED', id, message, page, itemsPerPage})
        },
        createThread: (product, shop, message, recipient) => {
            dispatch({type: 'CREATE_THREAD_REQUESTED', product, shop, message, recipient})
        },
        fetchProduct: (id) => {
            dispatch({type: 'FETCH_PRODUCT_REQUESTED', id})
        },
        fetchShop: (id) => {
            dispatch({type: 'FETCH_SHOP_REQUESTED', id})
        },
        cleanMessagesList: () => {
            dispatch({type: 'CLEAN_MESSAGES_LIST'})
        },
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
