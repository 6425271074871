import React from "react";
import clsx from 'clsx';

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import { Image } from "react-native";
import { TouchableOpacity } from 'react-native-web';

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import Hidden from "@material-ui/core/Hidden";

import Rating from "@material-ui/lab/Rating";

import AutorenewIcon from '@material-ui/icons/Autorenew';

import Text from "../Component/FontText";
import Picker from "../Component/CustomPicker";
import ShopSubscribeButton from "./Component/ShopSubscribeButton";
import { connect } from 'react-redux'
import { useParams, withRouter, Link, Redirect } from "react-router-dom";
import PointRelaisLogo from "../../Assets/Images/pointrelais_logo.svg";
import HandsLogo from "../../Assets/Images/hand_logo.svg";
import ClickAndCollectLogo from "../../Assets/Images/clickandcollect_logo.svg";
import ChronopostLogo from "../../Assets/Images/chronopost_logo.svg";
import { FontAwesome } from '../../FontAwesome';
import HorizontalSlider from "./Component/HorizontalSlider";
import ProductSquare from "./Component/ProductSquare"
import PictureModal from "./Component/PictureModal"
import Color from "./Component/Color"
import Moment from "moment"
import Map from "../Component/Map"

import ProductStylesFunc from "../../Style/ProductStyle";
import * as Constants from "../../Style/Constants";
import Loader from "../Component/Loader";
import SocialShareBar from "../Component/SocialShareBar";
import MetaTags from 'react-meta-tags';
import { getImageUrl } from '../../Utils/ImageUtils'
import UserIcon from "../../Assets/Images/user_icon.svg";
import { slugify } from "../../Utils/StringUtils"
import {getSingleProductDeliveryPrice} from "../../Utils/PriceUtils";


function VideDressing({history, navigation, width, screenKey, fetchProduct, user, fetchProductShop, fetchShopSimilarProducts, storeProduct, previewProduct, storeShop, cart, wishlist, addToCart, removeFromCart, addToWishlist, searchSimilarProducts, similarProducts, isLoading, shopSimilarProducts, resetError, productError, shippingPrices, params}) {
    let ProductStyles = ProductStylesFunc();
    let product;
    let shop;
    let id;
    let queryParams = useParams();
    if (screenKey && screenKey === "ProductPreview") {
        product = previewProduct;
        shop = user ? user.shop : null;
        if (shop) {
            shop.owner = user;
            if (shopSimilarProducts.length > 0) {
                shop.similarProducts = shopSimilarProducts;
            }
        }
        id = null;
    } else {
        params = queryParams;
        id = params.id;
        product = storeProduct;
        shop = storeShop;
    }
    let [quantity, setQuantity] = React.useState(1);
    let [visiblePicture, setVisiblePicture] = React.useState(0);
    let [selectedColor, setSelectedColor] = React.useState({id: -1});
    let [selectedSize, setSelectedSize] = React.useState({id: -1});

    let [displayPictureModal, setDisplayPictureModal] = React.useState(false);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        setVisiblePicture(0);
        if (id) {
            fetchProduct(id);
            fetchProductShop(id);
        } else if (product) {

        }

        window.scrollTo(0, 0);
    }, [id]);

    // React.useEffect(() => {
    //     if (productError) {
    //         if (productError === "NOT_FOUND") {
    //             history.push("/");
    //         }
    //         resetError();
    //     }
    // }, [productError]);

    React.useEffect(() => {
        if (shop && product && product.sizes && product.sizes.length > 0 && !shop.similarProducts) {
            shop.similarProducts = [];
            fetchShopSimilarProducts(shop.id, product.sizes[0].id);
        }
    }, [shop, product]);

    React.useEffect(() => {
        if (product) {
            document.title = "VioletteSauvage - " + product.name;
            if (product.quantity <= 0) {
                history.push(getCategoryUrl(product.family, product.category));
            } else {
                searchSimilarProducts(product);
                if (selectedColor.id === -1) {
                    setSelectedColor(product.colors[0]);
                }
                setSelectedSize(product.sizes && product.sizes.length > 0 ? product.sizes[0] : {id: -1});
            }
        }
    }, [product]);


    const [ locationKeys, setLocationKeys ] = React.useState([])
    React.useEffect(() => {
        window.scrollTo(0, 0);

        return history.listen(location => {
            window.scrollTo(0, 0);
            if (history.action === 'PUSH') {
                setLocationKeys([ location.key ])
            }

            if (history.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([ _, ...keys ]) => keys)
                } else {
                    setLocationKeys((keys) => [ location.key, ...keys ])
                }
            }
        });
    }, [locationKeys]);

    let similarProductsDom = [];
    for (const prod of similarProducts) {
        if (product && prod.id !== product.id) {
            similarProductsDom.push(<ProductSquare product={prod} />)
        }
    }

    let getCategoryUrl = (family, category) => {
        let result = "/category/" + product.family.id + "/" + slugify(product["family"]["name"]);
        const components = [];
        if (category) {
            components.push(slugify(category.name));
            components.push(category.id);
            if (category.parent) {
                components.push(slugify(category.parent.name));
                components.push(category.parent.id);
                if (category.parent.parent) {
                    components.push(slugify(category.parent.parent.name));
                    components.push(category.parent.parent.id);
                }
            }
        }

        return result + "/" + components.reverse().join("/");
    }


    let getColorValues = () => {
        const values = [];
        for (const color of product.colors) {
            values.push(
                {
                    react: <Color
                        color={color}
                        circleSize={Constants.FONT_SIZE_NORMAL}
                        textStyle={{fontSize: Constants.FONT_SIZE_NORMAL}}
                        wrapperStyle={{
                            padding: Constants.GET_SIZE(10),
                            paddingLeft: 0,
                            justifyContent: "center"
                        }}
                    />,
                    value: color,
                    label: <Color
                        color={color}
                        circleSize={Constants.FONT_SIZE_NORMAL}
                        textStyle={{fontSize: Constants.FONT_SIZE_NORMAL}}
                        wrapperStyle={{
                            padding: Constants.GET_SIZE(10),
                            paddingLeft: 0,
                            justifyContent: "center"
                        }}
                    />
                }
            );
        }
        return values;
    };

    let getSizeValues = () => {
        const values = [];
        for (const key in product.sizes) {
            values.push({
                value: product.sizes[key].value,
                label: product.sizes[key].value
            })
        }
        return values;
    };

    if (productError === "NOT_FOUND") {
        return <Box>
            <Box className={ProductStyles.container}>
                <Text className={ProductStyles.notFoundText}>Ce produit est introuvable</Text>
            </Box>
        </Box>;
    } else if (product) {
        // Ariane DOM
        let arianeDom = [];
        let currentCat = product["category"];
        arianeDom.unshift(<Link to={getCategoryUrl(product.family, currentCat)} style={{textDecoration: "none", color: "black"}}><Text className={ProductStyles.arianeText}>{currentCat["name"]}</Text></Link>);
        while (currentCat["parent"] != null) {
            currentCat = currentCat["parent"];
            arianeDom.unshift(<Link to={getCategoryUrl(product.family, currentCat)} style={{textDecoration: "none", color: "black"}}><Text className={ProductStyles.arianeText}>{currentCat["name"]}&nbsp;/&nbsp;</Text></Link>);
        }
        arianeDom.unshift(<Link to={getCategoryUrl(product.family, null)} style={{textDecoration: "none", color: "black"}}><Text className={ProductStyles.arianeText}>{product["family"]["name"]}&nbsp;/&nbsp;</Text></Link>);

        // Is product already in cart ?
        const isInCart = cart.reduce((acc, val) => acc || val.product.id === product.id, false);
        const isInWishlist = wishlist.reduce((acc, val) => acc || val.id === product.id, false);

        let shopProductsDom = [];
        if (shop && shop.similarProducts) {
            for (const oProduct of shop.similarProducts) {
                if (oProduct.id !== product.id) {
                    shopProductsDom.push(
                        <TouchableOpacity className={ProductStyles.shopOtherProductsItem} onPress={() => history.push("/product/" + oProduct["id"])}>
                            <Image source={getImageUrl(oProduct.pictures[0] ? oProduct.pictures[0].contentUrl : null, "medium")} className={ProductStyles.shopOtherProductsImage}/>
                        </TouchableOpacity>
                    )
                }
            }
        }

        let productAvailable = true;
        if (product.quantity <= 0) {
            productAvailable = false;
        }

        if (!product.chronopost && !product.mondialRelay && !product.handOver && !product.clickAndCollect) {
            productAvailable = false;
        }

        if (user && user.shop && product.shop && user.shop.id === product.shop.id) {
            productAvailable = false;
        }

        if (product.shop && product.shop.owner.status === 1) {
            productAvailable = false;
        }
        if (product.currentLive !== -1) {
            productAvailable = false;
        }
        return (
            <Box>
                <MetaTags>
                    <title>{product.name}{product.brand ? " - " + product.brand.value : ""}{product.sizes.length > 0 ? " - " + product.sizes[0].value : ""}{product.colors.length > 0 ? " - " + product.colors[0].value : ""} | Violette Sauvage</title>
                    <meta name="description"
                          content={product.description.substring(0, 130)}/>

                    <meta property="og:url"
                          content={"https://www.violettesauvage.net/product/" + product.id}/>
                    <meta property="og:type" content="product"/>
                    <meta property="og:title" content={product.name}/>
                    <meta property="og:description" content={product.description}/>
                    <meta property="og:image"
                          content={product.pictures && product.pictures[0] ? (product.pictures[0].contentUrl ? getImageUrl(product.pictures[0].contentUrl, "small") : product.pictures[0].uri) : null} />
                </MetaTags>
                <Box className={ProductStyles.container}>
                    <Grid container justify={"center"}>
                        <Grid item container xs={12} sm>
                            {arianeDom}
                        </Grid>
                        <Grid item container xs={12} sm className={ProductStyles.share} direction={"column"}>
                            <Text className={ProductStyles.shareTitle}>Partager</Text>
                            <SocialShareBar mailTitle={"Voici un super article !"}/>
                        </Grid>
                    </Grid>
                    <Box className={ProductStyles.productBloc}>
                        <Box className={ProductStyles.productImagesThumbnails}>
                            {product.pictures.map((picture, index) =>
                                <TouchableOpacity onPress={() => setVisiblePicture(index)}>
                                    <Image
                                        accessibilityLabel={product.name}
                                        source={picture ? (picture.contentUrl ? getImageUrl(picture.contentUrl, "small") : picture.uri) : null}
                                        className={ProductStyles.thumbnail}/>
                                </TouchableOpacity>)}
                        </Box>
                        <Box className={ProductStyles.productImagesContact}>
                            <Box className={ProductStyles.productImages}>
                                <Box className={ProductStyles.productSelectedPicture}>
                                    <Image
                                        accessibilityLabel={product.name}
                                        source={product.pictures[visiblePicture] ? (product.pictures[visiblePicture].contentUrl ? getImageUrl(product.pictures[visiblePicture].contentUrl, "big") : product.pictures[visiblePicture].uri) : null}
                                        className={ProductStyles.image}
                                        onClick={() => setDisplayPictureModal(true)}/>
                                </Box>
                            </Box>
                            <Button
                                onClick={() => history.push("/message/nouveau", {productId: product.id})}
                                variant={"contained"}
                                color={"primary"}
                                disableElevation>
                                Contacter
                            </Button>
                        </Box>
                        <Box className={ProductStyles.productInfo}>
                            <Box className={ProductStyles.productInfoName}>
                                <Text className={ProductStyles.productInfoNameText} component={"h1"}>{product.name}</Text>
                            </Box>
                            <Box className={ProductStyles.productInfoCatBrandPrice}>
                                <Text
                                    className={ProductStyles.productInfoCatBrandText}>{product.category.name}{product.brand ? " - " + product.brand.value : ""}</Text>
                                <Text className={ProductStyles.productInfoPriceText}>{product.realPrice} €</Text>
                            </Box>
                            <Box className={ProductStyles.productImagesContactSmall}>
                                <Box className={ProductStyles.productImages}>
                                    <HorizontalSlider
                                        nbElementsPerPage={1}
                                        elementList={product.pictures.map(picture => <CardMedia component={"img"}
                                                                                                image={picture.contentUrl ? getImageUrl(picture.contentUrl, "big") : picture.uri}
                                                                                                className={ProductStyles.image}
                                                                                                alt={product.name}
                                                                                                onClick={() => setDisplayPictureModal(true)}/>)}
                                        rowClass={ProductStyles.shopOtherProductsSlider}/>
                                </Box>
                            </Box>
                            <Text
                                className={clsx(ProductStyles.productInfoPriceText, ProductStyles.productInfoPriceTextMobile)}>{product.realPrice} €</Text>
                            <Box className={ProductStyles.productInfoStateMat}>
                                <Text
                                    className={ProductStyles.productInfoStateMatText}>• {product.state.value}</Text>
                                <Text
                                    className={ProductStyles.productInfoStateMatText}>• {product.material.value}</Text>
                            </Box>
                            {product.sizes && product.sizes.length > 0 &&
                            <Box className={ProductStyles.productInfoSize}>
                                <Text className={ProductStyles.sectionTitle}>Taille</Text>
                                <Box className={ProductStyles.productInfoSizesWrapper}>
                                    {product.sizes.map((size) =>
                                        <Button
                                            className={clsx(ProductStyles.productInfoSizeValue, {[ProductStyles.productInfoSizeValueSelected]: selectedSize.id === size.id})}
                                            onClick={() => setSelectedSize(size)}>
                                            <Text>{size.value}</Text>
                                        </Button>)
                                    }
                                </Box>
                                <Box className={ProductStyles.productInfoSizesWrapperMobile}>
                                    <Picker
                                        simpleValues={getSizeValues()}
                                        defaultValue={product.sizes[0].value}
                                        onPickValue={setSelectedSize}
                                        width={"100%"}
                                    />
                                </Box>
                            </Box>
                            }
                            {product.colors && product.colors.length > 0 &&
                            <Box className={ProductStyles.productInfoColor}>
                                <Text className={ProductStyles.sectionTitle}>Couleur</Text>
                                <Box className={ProductStyles.productInfoColorsWrapper}>
                                    {product.colors.map((color) =>
                                        <Button
                                            className={clsx(ProductStyles.productInfoColorValue, {[ProductStyles.productInfoColorValueSelected]: selectedColor.id === color.id})}
                                            onClick={() => setSelectedColor(color)}>
                                            <Color color={color} circleSize={Constants.FONT_SIZE_MEDIUM}/>
                                        </Button>)
                                    }
                                </Box>
                                <Box className={ProductStyles.productInfoColorsWrapperMobile}>
                                    <Picker
                                        reactValues={getColorValues()}
                                        defaultValue={product.colors[0]}
                                        onPickValue={setSelectedColor}
                                        width={"100%"}
                                    />
                                </Box>
                            </Box>
                            }
                            <Box className={ProductStyles.productQuantity}>
                                <Text className={ProductStyles.sectionTitle}>Quantité</Text>
                                <Hidden smUp>
                                    <Picker maxValue={product.quantity} defaultValue={"1"} onPickValue={setQuantity}
                                            width={"100%"} wrapperStyle={{marginBottom: 20}}/>
                                </Hidden>
                                <Box className={ProductStyles.buttonsRow}>
                                    <Hidden xsDown>
                                        <Picker maxValue={product.quantity} defaultValue={"1"}
                                                onPickValue={setQuantity}/>
                                    </Hidden>
                                    {(!user || !product.shop || user.id !== product.shop.owner.id) &&
                                    <Button disableElevation variant={"contained"}
                                            color={productAvailable ? "primary" : "default"} size={"small"}
                                            onClick={() => {
                                                !isInCart ? addToCart(product, quantity, selectedColor, selectedSize) : removeFromCart(product)
                                            }} className={ProductStyles.addToCartButton}
                                            disabled={productAvailable ? false : true}>
                                        {productAvailable ? (!isInCart ? "Ajouter au panier" : "Retirer du panier") : "Produit indisponible"}
                                    </Button>}
                                    <Button disableElevation variant={"contained"}
                                            color={!isInWishlist ? "default" : "primary"} onClick={() => {
                                        addToWishlist(product)
                                    }} size={"small"} className={ProductStyles.addToWishlistButton}>
                                        <FontAwesome name={"heart"} size={Constants.FONT_SIZE_NORMAL}
                                                     className={!isInWishlist ? '' : ProductStyles.buttonLabel}/>
                                    </Button>
                                </Box>
                                {product && product.currentLive !== -1 && (!screenKey || screenKey !== "ProductPreview") &&
                                    <Box className={ProductStyles.liveLinkWrapper}>
                                        <Text  className={ProductStyles.liveLinkLabel}>Ce produit est actuellement vendu en live. </Text>
                                        <Link to={"/voir_live/" + product.currentLive} className={ProductStyles.liveLink}><Text>Cliquez ici pour rejoindre ce live</Text></Link>
                                    </Box>}
                            </Box>
                            <Box className={ProductStyles.productImagesContactSmall}>
                                <Button
                                    onClick={() => history.push("/message/nouveau", {productId: product.id})}
                                    variant={"contained"}
                                    color={"primary"}
                                    size={"small"}
                                    disableElevation>
                                    Contacter
                                </Button>
                            </Box>
                            <Box className={ProductStyles.productDeliveryInfo}>
                                {product.returnPossible && <Box className={ProductStyles.productDeliveryInfoLine}>
                                    <Box className={ProductStyles.logoWrapper}>
                                        <AutorenewIcon style={{color: "#F8ADB1"}}/>
                                    </Box>
                                    <Text className={ProductStyles.deliveryDescription}>Retour possible</Text>
                                </Box>}
                                {product.chronopost && <Box className={ProductStyles.productDeliveryInfoLine}>
                                    <Box className={ProductStyles.logoWrapper}>
                                        <Image source={ChronopostLogo} className={ProductStyles.chronopostLogo}/>
                                    </Box>
                                    <Box>
                                        <Text className={ProductStyles.deliveryDescription}>Livraison 24h* à
                                            l’adresse de votre choix - {getSingleProductDeliveryPrice(product, shippingPrices, params, 'chronoPrice')} €</Text>
                                        <Text className={ProductStyles.deliveryDescriptionItalic}>*24h à compter du
                                            dépôt du vendeur.</Text>
                                    </Box>
                                </Box>}
                                {product.chronopost && <Box className={ProductStyles.productDeliveryInfoLine}>
                                    <Box className={ProductStyles.logoWrapper}>
                                        <Image source={ChronopostLogo} className={ProductStyles.chronopostLogo}/>
                                    </Box>
                                    <Text className={ProductStyles.deliveryDescription}>Livraison par chronopost en
                                        point relais - {getSingleProductDeliveryPrice(product, shippingPrices, params, 'chronoRelayPrice')} €</Text>
                                </Box>}
                                {product.mondialRelay && <Box className={ProductStyles.productDeliveryInfoLine}>
                                    <Box className={ProductStyles.logoWrapper}>
                                        <Image source={PointRelaisLogo} className={ProductStyles.pointRelaisLogo}/>
                                    </Box>
                                    <Text className={ProductStyles.deliveryDescription}>Livraison par Mondial Relay
                                        en point relais - {getSingleProductDeliveryPrice(product, shippingPrices, params, 'mondialRelayPrice')} €</Text>
                                </Box>}
                                {product.clickAndCollect && <Box className={ProductStyles.productDeliveryInfoLine}>
                                    <Box className={ProductStyles.logoWrapper}>
                                        <Image source={ClickAndCollectLogo}
                                               className={ProductStyles.clickAndCollectLogo}/>
                                    </Box>
                                    <Text className={ProductStyles.deliveryDescription}>Click and Collect au
                                        prochain vide dressing</Text>
                                </Box>}
                                {product.handOver && <Box className={ProductStyles.productDeliveryInfoLine}>
                                    <Box className={ProductStyles.logoWrapper}>
                                        <Image source={HandsLogo} className={ProductStyles.handsLogo}/>
                                    </Box>
                                    <Text className={ProductStyles.deliveryDescription}>Remise en main propre -
                                        gratuit.</Text>
                                </Box>}
                            </Box>
                        </Box>
                    </Box>
                    <Box className={ProductStyles.productBloc2}>
                        <Box className={ProductStyles.productionDescription}>
                            <Text className={ProductStyles.productionDescriptionTitle} component={"h2"}>Description du produit</Text>
                            <Text
                                className={ProductStyles.productionDescriptionContent}>{product.description}</Text>
                        </Box>
                    </Box>
                    {shop !== null &&
                    <Box className={ProductStyles.productBlocMap}>
                        <Map
                            center={[shop.latitude, shop.longitude]}
                            zoom={13}
                            visible={true}
                            mapClass={ProductStyles.productMap}
                            fixedViewpoint={true}
                            markers={[{position: [shop.latitude, shop.longitude], text: null}]}
                        />
                        {/*<Text className={ProductStyles.productMapAddress1}>{shop.facturationAddress}</Text>*/}
                        <Text className={ProductStyles.productMapAddress1}>{shop.facturationCity}</Text>
                    </Box>
                    }
                </Box>

                {shop !== null &&
                <Box className={ProductStyles.grayBlock}>
                    <Box className={ProductStyles.shopWrapper}>
                        <Grid container className={ProductStyles.shopTitleWrapper}>
                            <Grid item container xs={12} sm className={ProductStyles.shopTitleSubWrapper}>
                                <Text className={ProductStyles.shopTitle}>Infos vendeur -&nbsp;</Text>
                                <Text className={ProductStyles.shopTitle2} component={"h2"}>{shop.name}</Text>
                            </Grid>
                            <Grid item xs={12} md style={{maxWidth: 200}}>
                                <ShopSubscribeButton variant="outlined" shop={shop}
                                                     wrapperClass={ProductStyles.subscribeButton}/>
                            </Grid>
                        </Grid>
                        <Grid container className={ProductStyles.shopRow}>
                            <Grid item xs={12} md style={{maxWidth: 190}}>
                                {shop.picture &&
                                <CardMedia component={"img"} src={getImageUrl(shop.picture ? shop.picture.contentUrl : null, "medium")}
                                           className={ProductStyles.shopImage}/>}
                                {!shop.picture &&
                                <CardMedia component={"img"} src={UserIcon} className={ProductStyles.shopImage}/>}

                            </Grid>
                            <Grid container item xs={12} md className={ProductStyles.shopDescriptionColumn}
                                  justify={"center"} direction={"column"}>
                                <Rating value={product && product.shop ? product.shop.note : 0} readOnly/>
                                <Box>
                                    <Text className={ProductStyles.shopDescriptionDate}>
                                        Membre depuis le {Moment(shop.createdAt).format("DD/MM/YYYY")}
                                    </Text>
                                    <Text className={ProductStyles.shopDescriptionName}>
                                        Vendeur
                                        : {shop.owner ? shop.owner.firstname : ""} {shop.owner ? shop.owner.name : ""}
                                    </Text>
                                    <Text className={ProductStyles.shopDescriptionCity}>
                                        {shop.facturationCity}
                                    </Text>
                                    <Text className={ProductStyles.shopDescriptionDivider}>
                                        -
                                    </Text>
                                    <Text className={ProductStyles.shopDescriptionNbProducts}>
                                        {shop.nbProducts} produit{shop.nbProducts > 1 ? "s" : ""} en vente
                                    </Text>
                                    <Text className={ProductStyles.shopDescriptionNbProducts2}>
                                        {shop.nbSoldProducts} produit{shop.nbSoldProducts > 1 ? "s" : ""} vendu{shop.nbSoldProducts > 1 ? "s" : ""}
                                    </Text>
                                </Box>
                                <Box>
                                    <Button
                                        disableElevation variant={"contained"} color={"primary"}
                                        href={"/shop/" + shop.id}
                                        className={ProductStyles.button}>
                                        Accès boutique
                                    </Button>
                                </Box>
                            </Grid>
                            <Box className={ProductStyles.shopOtherProductsWrapper}>
                                {shopProductsDom.length > 0 &&
                                <Box>
                                    <Text className={ProductStyles.shopOtherProductsTitle}>Les autres
                                        produits</Text>
                                    <HorizontalSlider nbElementsPerPage={isWidthDown('xs', width) ? 1 : 3}
                                                      elementList={shopProductsDom} arrows={true}
                                                      rowClass={ProductStyles.shopOtherProductsSlider}/>
                                </Box>
                                }
                            </Box>
                        </Grid>
                    </Box>
                </Box>
                }
                {similarProductsDom.length > 0 && <Box className={ProductStyles.similarProductsWrapper}>
                    <Text className={ProductStyles.similarProductsTitle} component={"h2"}>Produits qui pourraient vous
                        intéresser</Text>
                    <Box className={ProductStyles.similarProductsList}>
                        <HorizontalSlider wrapperClass={ProductStyles.similarProductsListSlider}
                                          nbElementsPerPage={isWidthDown('xs', width) ? 1 : 4}
                                          elementList={similarProductsDom} arrows={true}/>
                    </Box>
                </Box>}
                {screenKey && screenKey === "ProductPreview" && <Box className={ProductStyles.previewBottomBar}>
                    <Button disableElevation variant={"contained"} color={"primary"}
                            onClick={() => window.history.back()}>
                        Fermer la prévisualisation
                    </Button>
                </Box>}
                <PictureModal visible={displayPictureModal} setVisible={setDisplayPictureModal}
                              picture={product.pictures[visiblePicture]}/>
            </Box>
        );
    } else {
        return (
            <Loader visible={isLoading > 0}  />
        );
    }
}

const mapStateToProps = state => {
    return {
        storeProduct: state.product.currentProduct,
        productError: state.product.productError,
        storeShop: state.shop.currentShop,
        user: state.user.me,
        cart: state.product.cart,
        wishlist: state.product.wishlist,
        previewProduct:state.product.previewProduct,
        similarProducts: state.product.similarProductsList,
        shopSimilarProducts: state.product.shopSimilarProductsList,
        isLoading: state.loading.isLoading,
        shippingPrices: state.family.shippingPrices,
        params: state.params.params
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchProduct: (id) => {
            dispatch({type: 'FETCH_PRODUCT_REQUESTED', id})
        },
        resetError: () => {
            dispatch({type: 'RESET_PRODUCT_ERROR'})
        },
        fetchProductShop: (id) => {
            dispatch({type: 'FETCH_PRODUCT_SHOP_REQUESTED', id})
        },
        searchSimilarProducts: (product) => {
            dispatch({type: 'SEARCH_SIMILAR_PRODUCTS_REQUESTED', product})
        },
        addToCart: (product, quantity, color, size) => {
            dispatch({type: 'ADD_TO_CART', product, quantity, color, size})
        },
        removeFromCart: (product) => {
            dispatch({type: 'REMOVE_FROM_CART', product})
        },
        addToWishlist: (product) => {
            dispatch({type: 'ADD_TO_WISHLIST', product, subscribe: !product.isWish})
        },
        fetchShopSimilarProducts: (shopId, size) => {
            dispatch({type: 'GET_SHOP_SIMILAR_PRODUCTS_REQUESTED', shopId, size})
        }
    }
};

const VisibleVideDressing = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VideDressing));

export default withWidth()(VisibleVideDressing)

// export default Login

