import axios from 'axios';
import { getApiUrl, setDefaultHeaders } from '../../Utils/QueryUtils'

export const FetchThreads = (search, page, itemsPerPage) => {
    return axios.get(getApiUrl("message_threads/my_threads", {search, page, itemsPerPage}), {headers: setDefaultHeaders()});
};

export const FetchThread = (id, page, itemsPerPage) => {
    return axios.get(getApiUrl("message_threads/" + id + "/messages", {page, itemsPerPage}), {headers: setDefaultHeaders()});
};

export const SendMessage = (id, message) => {
    return axios.post(getApiUrl("messages"), {"content": message, "thread": id}, {headers: setDefaultHeaders()});
};

export const CreateThread = (product, shop, message, recipient) => {
    const data = {product, shop, firstMessage: message};
    if (recipient !== null) {
        data['recipient'] = recipient;
    }
    return axios.post(getApiUrl("message_threads"), data, {headers: setDefaultHeaders()});
};
