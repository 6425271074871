import React from 'react';
import { useDispatch } from 'react-redux'
import axios from "axios";
import {getApiUrl, setDefaultHeaders} from "../../Utils/QueryUtils";
import socketClient from "socket.io-client";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ConfirmationModal from "../../Screens/Front/Component/ConfirmationModal";

const useStyles = makeStyles(theme => ({
    buttonBar: {
        [theme.breakpoints.up('sm')]: {
            marginTop: 10,
            "& :first-child": {
                marginRight: 5
            },
            "& :last-child": {
                marginLeft: 5
            }
        }
    }
}));

export function useNodeChat(host, token, me, onBanned, liveId, onMessageSent, onMessageReceived, onFirstLoad, onMyPropositionAccepted, onConnectionRefused, liveState, updateLiveState, updateLiveAdminState, updateLiveVideoState, reserveProduct, releaseProduct, onProductBought, updateLiveNbConnected) {
    const classes = useStyles();

    const [isLoading, setIsLoading] = React.useState(false);
    const [isLoadingSendMessage, setIsLoadingSendMessage] = React.useState(false);
    const [messageList, setMessageList] = React.useState([]);
    const [currentMessage, setCurrentMessage] = React.useState([]);
    const [socket, setSocket] = React.useState(null);
    const [socketHost, setSocketHost] = React.useState(host);
    const [stopConfirmationModal, setStopConfirmationModal] = React.useState(false);

    React.useEffect(() => {
        if (socketHost) {
            setSocket(socketClient(socketHost, {
                query: {
                    token: token,
                    liveId: liveId
                }
            }));
        }
    }, [socketHost]);

    React.useEffect(() => {

        if (socket) {
            const listeners = {
                'connect_error': (error) => {
                    if (onConnectionRefused) {
                        onConnectionRefused(error)
                    }
                },
                'fetchAllMessagesSucceeded': (result) => {
                    setMessageList(result);
                    if (onFirstLoad) {
                        onFirstLoad();
                    }
                },
                'fetchAllMessagesFailed': (status, error) => {
                    console.log(status, error);
                },
                'createMessageSucceeded': (result) => {
                    if (onMessageSent) {
                        onMessageSent();
                    }
                },
                'createMessageFailed': (status, error) => {
                    console.log(status, error);
                },
                'newMessage': (result) => {
                    setMessageList(messageList => [...messageList, result]);
                    if (onMessageReceived) {
                        onMessageReceived(result);
                    }
                },
                'messageRemoved': (result) => {
                    setMessageList(messages => {
                        const index = messages.findIndex((element) => element.id === result.id);
                        if (index !== -1) {
                            return [...messages.slice(0, index), ...messages.slice(index + 1)]
                        } else {
                            return messages
                        }
                    });
                },
                'userBanned': (data) => {
                    if (me.id === data.id) {
                        setMessageList([]);
                        onBanned();
                    } else {
                        setMessageList(messages => {
                            let result = [];
                            for (const element of messages) {
                                if (element.user.id !== data.id) {
                                    result.push(element);
                                }
                            }
                            return result;
                        });
                    }
                },
                'messageUpdated': (result) => {
                    setMessageList(messages => {
                        const index = messages.findIndex((element) => element.id === result.id);
                        if (index !== -1) {
                            return [
                                ...messages.slice(0, index),
                                Object.assign({}, messages[index], result),
                                ...messages.slice(index + 1)
                            ]
                        } else {
                            return messages
                        }
                    });
                },
                'propositionAccepted': (result) => {
                    if (result.user.id === me.id) {
                        onMyPropositionAccepted(result);
                    }
                },
                'liveStarted': (result) => {
                    updateLiveState(1);
                },
                'livePaused': (result) => {
                    updateLiveState(2);
                },
                'liveStopped': (result) => {
                    updateLiveState(3);
                },
                'adminConnectedChat': (result) => {
                    updateLiveAdminState(true);
                },
                'adminDisconnectedChat': (result) => {
                    updateLiveAdminState(false);
                },
                'videoStarted': (result) => {
                    updateLiveVideoState(1);
                },
                'videoStopped': (result) => {
                    updateLiveVideoState(0);
                },
                'productReserved': (result) => {
                    reserveProduct(result.id);
                },
                'productReleased': (result) => {
                    releaseProduct(result.id);
                },
                'productBought': (result) => {
                    onProductBought(result.id);
                },
                'connection': (data) => {
                    socket.emit('joinRoom', {liveId: liveId});
                    socket.emit('fetchAllMessages');
                },
                'userConnected': (data) => {
                    updateLiveNbConnected(true);
                },
                'userDisconnected': (data) => {
                    updateLiveNbConnected(false);
                }
            };
            for (const event in listeners) {
                socket.on(event, listeners[event]);
            }

            return () => {
                for (const event in listeners) {
                    socket.off(event);
                }
            }
        }
    }, [socket, onMyPropositionAccepted]);

    const sendMessage = () => {
        if (currentMessage) {
            socket.emit('createMessage', {content: currentMessage});
            setCurrentMessage('');
        }
    }

    const sendProposition = (productId, price) => {
        socket.emit('createProposition', {product_id: productId, price});
    }

    const deleteMessage = (id) => {
        socket.emit('deleteMessage', {id});
    }

    const banMessage = (userId) => {
        socket.emit('banMessage', {user_id: userId});
    }

    const acceptProposition = (message) => {
        socket.emit('acceptProposition', {message_id: message.id});
    }

    const refuseProposition = (message) => {
        socket.emit('refuseProposition', {message_id: message.id});
    }

    const buyProduct = (productId) => {
        socket.emit('buyProduct', {product_id: productId});
    }

    const startLive = () => {
        socket.emit('startLive', {});
    }

    const pauseLive = () => {
        socket.emit('pauseLive', {});
    }

    const stopLive = () => {
        socket.emit('stopLive', {});
    }

    const sendReserveProduct = (product) => {
        socket.emit('reserveProduct', {id: product.id});
    }
    const sendReleaseProduct = (product) => {
        socket.emit('releaseProduct', {id: product.id});
    }

    const getLiveAdminButtonBar = () => {
        const buttons = [];
        if (liveState === 0) {
            buttons.push(<Button variant={"contained"} color={"primary"} disableElevation size={"small"} onClick={startLive} key={"button-play"}>
                Démarrer le live
            </Button>);
        } else if (liveState === 1) {
            buttons.push(<Button variant={"contained"} color={"primary"} disableElevation size={"small"} onClick={pauseLive} key={"button-pause"}>
                Mettre en pause le live
            </Button>)
            buttons.push(<Button variant={"contained"} color={"secondary"} disableElevation size={"small"} onClick={setStopConfirmationModal} key={"button-stop"}>
                Arrêter le live
            </Button>)
        } else if (liveState === 2) {
            buttons.push(<Button variant={"contained"} color={"primary"} disableElevation size={"small"} onClick={startLive} key={"button-play-again"}>
                Reprendre le live
            </Button>)
        } else {
            return null;
        }
        return <Grid container justify={"center"} className={classes.buttonBar}>
            {buttons}

            <ConfirmationModal
                visible={stopConfirmationModal}
                setVisible={setStopConfirmationModal}
                title={"Arrêter le live"}
                message={"Êtes-vous sûr de vouloir arrêter votre live ? Vous ne pourrez plus le redémarrer par la suite."}
                onConfirm={() => {stopLive()}}
                onCancel={() => {setStopConfirmationModal(false)}}/>
        </Grid>
    }

    return [getLiveAdminButtonBar, messageList, currentMessage, setCurrentMessage, sendMessage, sendProposition, deleteMessage, banMessage, acceptProposition, refuseProposition, isLoading, isLoadingSendMessage, sendReserveProduct, sendReleaseProduct, buyProduct];
}
