import React from "react";
import clsx from 'clsx';
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import InputAdornment from '@material-ui/core/InputAdornment';
import Text from "../../Component/FontText";
import { Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { FontAwesome } from '../../../FontAwesome';

import KeyIcon from '@material-ui/icons/VpnKey';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HomeIcon from '@material-ui/icons/Home';
import PhoneIcon from '@material-ui/icons/Phone';
import VisibilityIcon from '@material-ui/icons/Visibility';

import ErrorStyleFunc from '../../Styles/Error'

import TextInputIconStylesFunc from '../../../Style/TextInputIconStyle'
import * as Constants from '../../../Style/Constants'

const ICONS = {
    "key": className => <KeyIcon className={className}/>,
    "user-circle": className => <AccountCircleIcon className={className}/>,
    "user": className => <AccountCircleIcon className={className}/>,
    "home": className => <HomeIcon className={className}/>,
    "phone": className => <PhoneIcon className={className}/>,
    "eye": (className, onClick) => <VisibilityIcon className={className} onClick={onClick} />,
};

function TextInputIcon(props) {
    let {register, errors, setValue, autoCompleteType, onBlur, onFocus, defaultValue, value, control, icon, placeholder, rules, name, secure, multiline, wrapperStyle, inputWrapperStyle, wrapperClass, inputWrapperClass, disabled, errorStyle, inputStyle, errorClass, inputClass, topLabel, suffix, suffixClass, label, inputComponentClass, onPressEnter, type, enterkeyhint} = props;
    let ErrorStyle = ErrorStyleFunc();
    let TextInputIconStyles = TextInputIconStylesFunc();
    let [hidePassword, setHidePassword] = React.useState(secure);

    let getCustomTextInput = () => {
        return <TextField
            inputRef={register ? register(rules) : null}
            name={name}
            placeholder={placeholder}
            className={clsx(inputClass, {[TextInputIconStyles.disabledOverride]: disabled})}
            style={inputStyle}
            type={hidePassword ? 'password' : type ? type : 'text'}
            multiline={multiline}
            rows={11}
            onBlur={onBlur}
            onFocus={onFocus}
            enterkeyhint={enterkeyhint}
            onKeyDown={(event) => (onPressEnter && event.keyCode === 13) ? onPressEnter() : null}
            autoCompleteType={autoCompleteType}
            autoComplete={autoCompleteType === "off" ? "chrome_hack_autocomplete_off" : "on"}
            disabled={disabled ? disabled : false}
            variant={"outlined"}
            error={errors && errors[name]}
            defaultValue={defaultValue ? defaultValue : ""}
            value={value ? value : null}
            onChange={setValue ? (event) => setValue(event.target.value) : null}
            label={label ? label : null}
            inputProps={{
                enterkeyhint: enterkeyhint,
            }}
            InputProps={{
                startAdornment: icon &&
                    <InputAdornment position="start" style={{borderRightWidth: 1}}>
                        {ICONS[icon](clsx(TextInputIconStyles.inputIcon, {[ErrorStyle.error]: errors && errors[name]}))}
                    </InputAdornment>,
                endAdornment: secure ?
                    <InputAdornment position="end">
                        {ICONS["eye"](
                            clsx(TextInputIconStyles.inputIconRight, {[ErrorStyle.error]: errors && errors[name]}),
                            () => setHidePassword(!hidePassword)
                        )}
                    </InputAdornment> :
                    suffix ?
                        <InputAdornment position="end">{suffix}</InputAdornment> :
                    null,
                className: inputComponentClass
            }}
        />
    };

    return (<Grid container item className={clsx(TextInputIconStyles.wrapper, wrapperClass)}>
        {(topLabel && topLabel !== null) && <Grid item xs={12}>
            <Text style={{marginTop: Constants.GET_SIZE(10), fontSize: Constants.FONT_SIZE_NORMAL, width: "100%"}}>{topLabel} :</Text>
        </Grid>}
        <Grid item xs={12}>
        {(control && !register) ?
            <Controller
                as={getCustomTextInput}
                name={name}
                control={control}
                rules={rules}
                defaultValue={defaultValue ? defaultValue : ""}
                // onChange={value => setValue(value)}

            /> : getCustomTextInput()
                // inputRef={register(rules)}
                // control={control}
                // onChangeText={text => setValue(text)}//.replace(suffix, ""))}
                // value={value ? value : ""}
        }
        </Grid>
        {errors && errors[name] && <Grid item xs={12}>
            <ErrorMessage name={name} errors={errors} render={({message}) => <Text color={"error"}>{message}</Text>} style={errorStyle}/>
        </Grid>}
    </Grid>);
}

export default TextInputIcon;
